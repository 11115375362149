import { IFormSectionProps } from '../types'

/**
 *
 */
export default function FormSection({ label, children }: IFormSectionProps) {
    return (
        <div className="mb-10">
            <h2 className="font-bold text-2xl text-gray-800">{label}</h2>
            <hr className="border-2 mb-5" />

            <div className="px-3">{children}</div>
        </div>
    )
}
