import { ITabNavProps } from "../types";

/**
 * 
 */
export default function TabNav({
    label,
    onClick,
    value,
    isActive
}: ITabNavProps) {
    const handleOnClick = () => onClick(value);
    return (
        <button type="button" onClick={handleOnClick} className={`px-4 py-1 transition-colors ${isActive ? `text-white ${value==="exam" ? 'bg-blue-500': 'bg-red-400'}` : "bg-gray-200"}`}>
            {label}
        </button>
    )
}