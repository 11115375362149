import { useState } from "react"
import { useQueryHook } from "../../hooks/react-query/useQueryHook"
import { VscLoading } from "react-icons/vsc"
import CategoryCard from "../../components/CategoryCard"
import { ICategory, ICategoryListProps } from "../../types"
import NoContentSection from "../../components/NoContentSection"

export default function CategoryList({ type }: ICategoryListProps) {
    const [categories, setCategories] = useState([])

    const handleCategoryFetchSuccess = (response: any) => {
        setCategories(response.data.results)
    }

    const { isLoading:isExamLoading, isRefetching:isExamRefetching } = useQueryHook({
        queryName: 'category-listing',
        queryRoute: `/categories/?limit=50&for_test=false`,
        options: {
            onSuccess: handleCategoryFetchSuccess,
            enabled: type === 'exam'
        },
    })

    const { isLoading:isTestLoading, isRefetching :isTestRefetching} = useQueryHook({
        queryName: 'category-listing',
        queryRoute: `/categories/?limit=50&for_test=true`,
        options: {
            onSuccess: handleCategoryFetchSuccess,
            enabled: type === "test"
        },
    })

    const isLoading = isExamLoading || isTestLoading;
    const isRefetching = isExamRefetching || isTestRefetching;

    return (
        <>
            {isLoading || isRefetching ?
                <div className="min-h-[40vh] flex justify-center items-center">
                    <VscLoading className="animate-spin text-primary-200" fontSize={48} />
                </div>
                :
                categories.length > 0 ?
                    <div className="grid grid-cols-3 gap-10">
                        {categories.map((category: ICategory) => (
                            <CategoryCard category={category} key={category.id} />
                        ))}
                    </div>
                    :
                    <NoContentSection title='Subjects' />
            }
        </>
    )
}