import { useCallback, useEffect, useState } from "react";
import { ISpeechProps } from "../types";

export default function Speech({ text, play, repeat = 1 }: ISpeechProps) {
    const [isPaused, setIsPaused] = useState(false);
    const [utterance, setUtterance] = useState<any>(null);
    const [voices, setVoices] = useState<any>([]);

    useEffect(() => {
        const synth = window.speechSynthesis;
        const u = new SpeechSynthesisUtterance(text.repeat(repeat));
        setVoices(synth.getVoices());

        setUtterance(u);

        return () => {
            synth.cancel();
        };
    }, [repeat, text]);

    const handlePlay = useCallback(() => {
        if (!utterance) return;
        const synth = window.speechSynthesis;

        if (isPaused) {
            synth.resume();
        }

        utterance.rate = 0.6;
        if (voices.length) {
            utterance.voice = voices.find((voice: any) => voice.voiceURI === "Google US English");
        }
        if (!repeat) {
            setIsPaused(false);
        }
        synth.speak(utterance);

    }, [isPaused, repeat, utterance, voices]);

    const handleStop = () => {
        const synth = window.speechSynthesis;

        synth.cancel();

        setIsPaused(false);
    };

    useEffect(() => {
        if (play) {
            handlePlay();
        }
        else {
            handleStop()
        }
    }, [handlePlay, play])

    return (
        <div></div>
    )
}