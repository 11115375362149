import jsCookie from 'js-cookie'
import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from './store'
import { LOCAL_ACCESS_TOKEN_NAME, LOCAL_REFRESH_TOKEN_NAME } from '../constants'
import localforage from 'localforage';

const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null },
    reducers: {
        setCredentials: (state, action) => {
            state.token = action.payload
        },
        logOut: (state) => {
            state.token = null
        },
    },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const logOutUser = () => (dispatch: AppDispatch) => {
    localStorage.removeItem(LOCAL_ACCESS_TOKEN_NAME)
    jsCookie.remove(LOCAL_REFRESH_TOKEN_NAME)
    localforage.clear().then(function () {
        console.log('Database is now empty.');
    }).catch(function (err) {
        console.log(err);
    });
    dispatch(logOut())
}

export const handleSetCredentials =
    (data: { access: 'string'; refresh: 'string' }) =>
        (dispatch: AppDispatch) => {
            const { access, refresh } = data;
            localStorage.setItem(LOCAL_ACCESS_TOKEN_NAME, access)
            jsCookie.set(LOCAL_REFRESH_TOKEN_NAME, refresh)
            dispatch(setCredentials(access))
        }
