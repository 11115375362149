import SuperAdminHeader from '../components/SuperAdminHeader'

/**
 * 
 */
export default function SuperAdminLayout({ children }: any) {
    return (
        <div className="min-h-screen w-full flex flex-col bg-light-200">
            <SuperAdminHeader />

            <div className="bg-light-100 m-8 p-8">{children}</div>
        </div>
    )
}
