import { useTimer } from 'react-timer-hook'
import { DANGER_TIME_IN_SECOND } from '../constants';

export default function TableDataTimer({ isRunning, endTime }: { isRunning: boolean, endTime: string }) {
    const expiryTimestamp = isRunning && endTime ? new Date(endTime) : new Date();

    const { totalSeconds, seconds, minutes, hours } = useTimer({
        expiryTimestamp,
        autoStart: isRunning,
    })

    return (
        <span
            className={`font-orbitron font-bold tracking-widest ${totalSeconds < DANGER_TIME_IN_SECOND
                ? 'text-red-600 font-extrabold'
                : 'text-gray-700'
                }`}
        >
            <span>{('0' + hours).slice(-2)}</span>:
            <span>{('0' + minutes).slice(-2)}</span>:
            <span>{('0' + seconds).slice(-2)}</span>
        </span>
    )
}
