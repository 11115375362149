import { useForm } from 'react-hook-form'
import FormInput from '../../components/FormInput'

import Button from '../../components/Button'

import { IOption, IUserCreateProps, IUserProps } from '../../types'
import {
    useMutationHook,
    useQueryHook,
} from '../../hooks/react-query/useQueryHook'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { userSchema } from '../../schemaValidations'
import { useEffect, useState } from 'react'
import FormSelect from '../../components/FormSelect'
import { ROLE_NAME, USER_ROLES } from '../../constants'

export default function UserForm({ user, activeTab, hideForm }: IUserCreateProps) {
    const queryClient = useQueryClient()
    const [organizations, setOrganizations] = useState()
    const [categories, setCategories] = useState([])

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
        clearErrors,
        watch,
    } = useForm({
        defaultValues: {
            first_name: "",
            last_name: "",
            role: "",
            phone: "",
            username: "",
            organization: "",
            assigned_categories: [],
        },
        resolver: yupResolver(userSchema(Boolean(user))),
    })

    useEffect(() => {
        if (!user) return
        setValue('first_name', user.first_name)
        setValue('last_name', user.last_name)
        setValue('role', user.role)
        setValue('username', user.username)
        setValue('phone', user.phone)

        if (user.role === 'invigilator') {
            setValue('organization', user.organization);
        }
        if (user.assigned_categories) {
            setValue('assigned_categories', user.assigned_categories)
        }
    }, [user, setValue])

    useQueryHook({
        queryName: 'organizations',
        queryRoute: `/organizations/`,

        options: {
            onSuccess: (response: any) => {
                setOrganizations(
                    response.data.results.map((org: any) => ({
                        label: org.name,
                        value: org.id,
                    }))
                )
            },
        },
    })

    useQueryHook({
        queryName: 'categories',
        queryRoute: '/categories/?limit=100',
        options: {
            onSuccess: (response: any) => {
                setCategories(
                    response.data.results.map((category: any) => ({
                        label: `${category.title} ${category.for_test ? "[ Internal ]" : ""}`,
                        value: category.id,
                    }))
                )
            },
        },
    })

    const { mutate, isLoading: isCreateCategoryLoading } = useMutationHook({
        queryRoute: '/user/',
        options: {
            onSuccess: () => {
                queryClient.invalidateQueries(['users'])

                hideForm()
                reset()
            },
            onError: (err) => {
                toast.error(err.detail)
            },
        },
    })

    const { mutate: updateCategory, isLoading: isUpdateCategoryLoading } =
        useMutationHook({
            queryRoute: `/user/${user?.id}/`,
            method: 'patch',
            options: {
                onSuccess: () => {
                    queryClient.invalidateQueries(['users'])

                    hideForm()
                    reset()
                },
                onError: (err) => {
                    toast.error(err.detail)
                },
            },
        })

    const isLoading = isCreateCategoryLoading || isUpdateCategoryLoading

    const onSubmit = (data: IUserProps) => {
        data.email = data.username;

        if (data.password === '') {
            delete data.password;
        }
        if (user) {
            updateCategory(data)
        } else {
            mutate(data)
        }
    }

    const giveRole = watch('role')

    return (
        <div className="py-5 px-5 bg-gray-100 rounded mb-8">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <FormInput
                        name="first_name"
                        label="First Name"
                        register={register}
                        errors={errors}
                        disabled={isLoading}
                    />

                    <FormInput
                        name="last_name"
                        label="Last Name"
                        register={register}
                        errors={errors}
                        disabled={isLoading}
                    />

                    <FormInput
                        type="email"
                        name="username"
                        label="Email"
                        register={register}
                        errors={errors}
                        disabled={isLoading}
                    />

                    <FormInput
                        type="password"
                        name="password"
                        label="Password"
                        register={register}
                        errors={errors}
                        disabled={isLoading}
                    />

                    <FormInput
                        type="number"
                        name="phone"
                        label="Phone"
                        register={register}
                        errors={errors}
                        disabled={isLoading}
                    />

                    {user?.role !== 'applicant' && (
                        <FormSelect
                            name="role"
                            label="Role"
                            setValue={setValue}
                            errors={errors}
                            disabled={isLoading}
                            clearErrors={clearErrors}
                            options={USER_ROLES}
                            defaultValue={
                                user
                                    ? {
                                        value: user.role,
                                        label: ROLE_NAME[user.role],
                                    }
                                    : undefined
                            }
                        />
                    )}

                    {giveRole === 'invigilator' && (
                        <FormSelect
                            key={user?.id}
                            name="organization"
                            label="Organization"
                            setValue={setValue}
                            errors={errors}
                            disabled={isLoading}
                            clearErrors={clearErrors}
                            options={organizations}
                            defaultValue={
                                user
                                    ? {
                                        value: user.organization || '',
                                        label: user.organization_name,
                                    } as IOption
                                    : undefined
                            }
                        />
                    )}

                    {giveRole === 'question_admin' &&
                        <>
                            <FormSelect
                                key={user && categories.length}
                                name="assigned_categories"
                                label='Assigned subjects'
                                setValue={setValue}
                                errors={errors}
                                disabled={isLoading}
                                clearErrors={clearErrors}
                                options={categories}
                                defaultValue={
                                    user && categories.length
                                        ?
                                        categories.filter((category: any) => user.assigned_categories?.includes(category.value))
                                        : undefined
                                }
                                isMulti
                                closeMenuOnSelect={false}
                            />
                        </>
                    }

                </div>

                <div className="flex justify-end gap-4">
                    <Button
                        className="mt-5"
                        label="Cancel"
                        varient="outline-danger"
                        disabled={isLoading}
                        isLoading={isLoading}
                        type="button"
                        onClick={hideForm}
                    />
                    <Button
                        className="mt-5"
                        label="Submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                </div>
            </form>
        </div>
    )
}
