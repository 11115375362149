
//
import { IButtonProps } from '../types'

import { VscLoading } from 'react-icons/vsc'

/**
 * 
 */
export default function Button({
    varient = 'primary',
    label,
    type = 'submit',
    onClick,
    icon,
    disabled,
    isLoading,
    className,
    title
}: IButtonProps) {
    let classes =
        'inline-flex items-center px-4 py-2 border shadow-sm text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-75 '
    switch (varient) {
        case 'primary':
            classes +=
                'border-transparent text-white bg-primary-200 hover:bg-blue-500 focus:ring-primary-100'
            break
        case 'outline-primary':
            classes +=
                'border-primary-200 text-primary-200 bg-white hover:bg-primary-200 hover:text-white focus:ring-primary-100'
            break
        case 'secondary':
            classes +=
                'border-transparent text-white bg-secondary-200 hover:bg-secondary-600 focus:ring-secondary-400'
            break
        case 'outline-secondary':
            classes +=
                'border-secondary-200 text-secondary-200 bg-white hover:bg-secondary-200 hover:text-white focus:ring-secondary-400'
            break
        case 'complimentary':
            classes +=
                'border-transparent text-white bg-red-500 hover:bg-orange-600 focus:ring-orange-400'
            break
        case 'outline-complimentary':
            classes +=
                'border-orange-500 text-orange-500 bg-white hover:bg-orange-500 hover:text-white focus:ring-orange-500'
            break
        case 'danger':
            classes +=
                'border-red-500 text-white bg-red-500 hover:bg-white hover:text-red-500 focus:ring-red-400'
            break
        case 'outline-danger':
            classes +=
                'border-red-500 text-red-500 bg-white hover:bg-red-500 hover:text-white focus:ring-red-500'
            break
        default:
    }
    return (
        <button
            type={type}
            className={`transition-colors ${classes} ${className ?? ''}`}
            onClick={() => onClick?.()}
            disabled={disabled}
            title={title || label}
        >
            {isLoading ? (
                <VscLoading className="animate-spin" />
            ) : (
                <>
                    {icon && (
                        <span className={`${label ? 'mr-2' : ''}  block`}>
                            {icon}
                        </span>
                    )}

                    {label}
                </>
            )}
        </button>
    )
}
