export const publicSocketEventType = {
	'call_invigilator': 'call_status',
	'test_status': 'test_status'
};

export const invigilatorSocketEventType = {
	'call_invigilator': 'call_status',
	'test_status': 'test_status'
}
export const applicantSocketEventType = {
	'user_join': 'user_join'
}