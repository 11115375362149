import { toast } from 'react-toastify'
import { AppDispatch } from './store'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    caller: {},
    examStudentInfo: {}
}

const socketSlice = createSlice({
    name: 'socket',
    initialState: initialState,
    reducers: {
        setCaller: (state, action) => {
            state.caller = action.payload
        },
        clearCaller: (state) => {
            state.caller = {}
        },
        setExamStudentInfo: (state, action) => {
            state.examStudentInfo = action.payload
        },
        clearExamStudentInfo: (state) => {
            state.examStudentInfo = {};
            state.caller = {};
        },
    },
})

export const { setCaller, clearCaller, setExamStudentInfo, clearExamStudentInfo } = socketSlice.actions

export default socketSlice.reducer

export const handleSetCaller = (data: any) => (dispatch: AppDispatch) => {
    toast.info("Student is calling!");

    dispatch(setCaller(data));
}

export const handleClearCaller = () => (dispatch: AppDispatch) => {
    dispatch(clearCaller());
}

export const handleSetExamStudentInfo = (data: any) => (dispatch: AppDispatch) => {
    dispatch(setExamStudentInfo(data));
}

export const handleClearExamStudentInfo = () => (dispatch: AppDispatch) => {
    dispatch(clearExamStudentInfo());
}