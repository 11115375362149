import { Disclosure } from '@headlessui/react'

//
import { IoChevronUp } from 'react-icons/io5'
import { IPreviewQuestionItemProps } from '../../types'
import { FaCheck } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'

/**
 * 
 */
export default function PreviewQuestionItem({ question, sn, defaultOpen }: IPreviewQuestionItemProps) {
    return (
        <Disclosure key={`${sn}${defaultOpen}`} defaultOpen={defaultOpen}>
            {({ open }) => (
                <>
                    <Disclosure.Button className={`flex w-full justify-between items-center bg-gray-100 px-4 py-2 text-left text-sm text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75 ${open ? "" : "mb-2"}`}>
                        <p>
                            <span className="mr-2">{sn}.</span><span>{question.question}</span> &nbsp;{' '}
                        </p>
                        <div className="flex">
                            <IoChevronUp
                                className={`transition-transform ${open
                                    ? 'rotate-180 transform'
                                    : ''
                                    } h-5 w-5 text-gray-500`}
                            />
                        </div>
                    </Disclosure.Button>
                    <Disclosure.Panel>
                        <div className="mb-4">

                            {question.options.map((option, idx) => {
                                return (
                                    <p
                                        className={`flex justify-between items-center ${option.is_correct
                                            ? 'bg-green-100'
                                            : 'bg-red-100'
                                            } py-1 px-4 mb-1 text-sm text-gray-700`}
                                        key={option.id}
                                    >
                                        {option.option}

                                        {option.selected &&
                                            <span className='flex gap-4 items-center'>Your answer:
                                                {
                                                    option.is_correct ?
                                                        <span className='flex items-center gap-x-1 text-green-600 font-semibold'><FaCheck /> Correct</span>
                                                        :
                                                        <span className='flex items-center gap-x-1 text-red-600 font-semibold'><IoClose fontSize={20} /> Incorrect</span>
                                                }
                                            </span>}


                                    </p>
                                )
                            })}
                        </div>
                    </Disclosure.Panel>
                </>
            )
            }
        </Disclosure >
    )
}