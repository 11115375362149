import { IOrganizationCategoriesListingProps, SubjectType } from "../types";

//
import CategoriesCountInput from "./CategoriesCountInput";

/**
 * 
 */
export default function OrganizationCategoriesListing({
    organizationId,
    categoriesPreferences,
    is_test_organization
}: IOrganizationCategoriesListingProps) {
    return (
        <div>
            <div className="p-4 mb-4 bg-gray-100 rounded">
                <div className="flex justify-between items-center">
                    <h3 className="text-xl text-gray-800 font-semibold mb-4">Questions settings</h3>
                </div>
                {
                    organizationId &&
                    <CategoriesCountInput
                        organizationId={organizationId}
                        categoriesPreferences={categoriesPreferences}
                        forTest={is_test_organization}
                    />
                }
            </div>
        </div>
    )
}