import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from './store'
import { IStudentRegister, IUserProps } from '../types'
import { LOCAL_HAS_EXAM_EFFECT_RAN, LOCAL_HAS_REFRESHED_EXAM } from '../constants'

const initialState: { user: IStudentRegister } = {
    user: {
        first_name: '',
        last_name: '',
        date_of_birth: '',
        licence_type: '',
        type: '',
        number: 0,
        country: '',
        validity: '',
        organization: 0,
    },
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        resetUser: (state) => ({
            ...initialState
        })
    },
})

export const { setUser, resetUser } = userSlice.actions

export default userSlice.reducer

export const handleSetUser = (data: IUserProps) => (dispatch: AppDispatch) => {
    dispatch(setUser(data));
    localStorage.setItem(LOCAL_HAS_EXAM_EFFECT_RAN, 'false');
    localStorage.setItem(LOCAL_HAS_REFRESHED_EXAM, 'false');
}

export const handleResetUser = () => (dispatch: AppDispatch) => {
    dispatch(resetUser())
}