import { useParams } from "react-router-dom";
import SuperAdminLayout from "../../layout/SuperAdminLayout";
import { useQueryHook } from "../../hooks/react-query/useQueryHook";
import { IFieldProps } from "../../types";
import dayjs from "dayjs";
import { getPercentage } from "../../helpers/methods";
import PreviewQuestionItem from "./PreviewQuestionItem";
import { useState } from "react";
import { VscLoading } from "react-icons/vsc";
import BackButton from "../../components/BackButton";
import Button from "../../components/Button";
import { IoChevronUpSharp } from "react-icons/io5";

export default function ApplicantResultPreview() {
    const { id, profileId } = useParams();

    const [defaultOpen, setDefaultOpen] = useState<boolean>(false);

    const { data, isLoading, isRefetching } = useQueryHook({
        queryName: 'users',
        queryRoute: `/user/${id}/`,
    });

    const { data: examData, isLoading: loadingExam, isRefetching: isExamRefetching } = useQueryHook({
        queryName: `applicant/${profileId}/result`,
        queryRoute: `/applicant/${profileId}/result/`,
    });

    const user = data?.data ?? { profile: {} };
    const exam = examData?.data || [];
    const loading = isRefetching || isLoading;
    const isExamLoading = loadingExam || isExamRefetching;

    const toggleDefaultOpen = () => setDefaultOpen(prev => !prev);
    return (
        <SuperAdminLayout>
            <div>
                <div className='flex group mb-4'>
                    <BackButton />
                    <h2 className="text-3xl font-bold text-gray-800">
                        Applicant Info
                    </h2>
                </div>
                {
                    loading ?
                        <LoadingSection />
                        :
                        <div className="px-2 grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            <FieldValue
                                label="Full name"
                                value={`${user.first_name} ${user.last_name}`}
                            />
                            <FieldValue
                                label="Licence Type"
                                value={user.profile.licence_type}
                            />
                            <FieldValue
                                label="Licence number"
                                value={user.profile.licence_number}
                            />
                            <FieldValue
                                label="Organization"
                                value={user.organization_name}
                            />


                            <FieldValue
                                label="Exam Date"
                                value={dayjs(user.profile.start_time).format('YYYY-MM-DD')}
                            />
                            <FieldValue
                                label="Exam Start"
                                value={dayjs(user.profile.start_time).format('hh:mm A')}
                            />
                            <FieldValue
                                label="Exam End"
                                value={dayjs(user.profile.end_time).format('hh:mm A')}
                            />
                            <FieldValue
                                label="Obtained Result"
                                value={getPercentage(user.profile.obtained_marks, user.profile.total_marks)}
                            />
                        </div>
                }


                <hr className="my-6" />

                <div>
                    <div className="flex w-full justify-between items-center sticky top-0 bg-white">
                        <h2 className="text-3xl font-semibold text-gray-700 mb-4">
                            Exam
                        </h2>

                        <div>
                            <Button
                                icon={<IoChevronUpSharp className={`${defaultOpen ? "transition-transform rotate-180" : ""}`} />}
                                onClick={toggleDefaultOpen}
                            />
                        </div>

                    </div>

                    {
                        isExamLoading ?
                            <LoadingSection />
                            :
                            <div>
                                {
                                    exam?.map((question: any, index: number) => <PreviewQuestionItem
                                        key={question.id}
                                        question={question}
                                        sn={index + 1}
                                        defaultOpen={defaultOpen}
                                    />)
                                }
                            </div>
                    }





                </div>
            </div>
        </SuperAdminLayout>
    )
}

function FieldValue({ value, label }: IFieldProps) {
    return (
        <div className="text-gray-700">
            <div className="text-sm capitalize">{label}</div>
            <div className="text-2xl capitalize">{value}</div>
        </div>
    )
}

function LoadingSection() {
    return (
        <div className="min-h-[200px] w-full grid place-content-center">
            <VscLoading className="animate-spin text-primary-200" fontSize={48} />
        </div>
    )
}