import dayjs from 'dayjs';
import Swal from 'sweetalert2'
import { useState } from 'react';
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce';
import { useQueryClient } from 'react-query'
import Select, { SingleValue } from 'react-select';
import { useNavigate, useSearchParams } from 'react-router-dom';

//
import {
    useMutationHook,
    useQueryHook,
} from '../../hooks/react-query/useQueryHook'

//
import SuperAdminLayout from '../../layout/SuperAdminLayout'

//
import UserForm from './UserForm'
import Button from '../../components/Button'
import Pagination from '../../components/Pagination'
import DownloadBtn from '../../components/DownloadBtn'
import SearchInput from '../../components/SearchInput';
import ResultModal from '../../components/ResultModal';
import CustomTable, { Action } from '../../components/CustomTable'

//
import { IoMdAdd } from 'react-icons/io'
import { FaEye, FaPrint } from 'react-icons/fa';

//
import { getPercentage } from '../../helpers/methods'

//
import { IOrganization, IOrganizationProps, IStudentRegister, IUserProps } from '../../types'
import { DateInput } from '../../components/DateInput';

/**
 * 
 */
export default function QuestionAdminList() {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const todayDate = dayjs().format('YYYY-MM-DD');

    const [questionAdmin, setQuestionAdmin] = useState([])
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(false)
    const [user, setUser] = useState<IUserProps>()
    const [role, setRole] = useState(() => {
        let role = searchParams.get('role');
        if (role) return role;
        return 'question_admin'
    })
    const [search, setSearch] = useState('');
    const [searchFrom, setSearchFrom] = useState(todayDate);
    const [searchTo, setSearchTo] = useState(todayDate);
    const [searchOrganization, setSearchOrganization] = useState<number | string>('');


    const [showResultModal, setShowResultModal] = useState(false);
    const [resultUser, setResultUser] = useState<IStudentRegister | null>(null);


    const [debouncedSearchValue] = useDebounce(search, 1000);

    const handleFromChange = (data: string) => setSearchFrom(data);
    const handleToChange = (data: string) => setSearchTo(data);

    const handleOrganizationChange = (orgId: number) => setSearchOrganization(orgId)

    const handleTabChange = (key: string) => {
        setRole(key);
        setSearchParams({ role: key })
        if (key !== "applicant") {
            setSearchTo('');
            setSearchFrom('');
            setSearchOrganization('');
        }
    }

    const tabs = [
        {
            name: 'Question Admin',
            key: 'question_admin',
            onClick: handleTabChange,
        },
        {
            name: 'Invigilator',
            key: 'invigilator',
            onClick: handleTabChange,
        },
        {
            name: 'Staff',
            key: 'staff',
            onClick: handleTabChange,
        },
        {
            name: 'Applicant',
            key: 'applicant',
            onClick: handleTabChange,
        },
    ]

    const handleOpenUserForm = () => {
        if (open) {
            setUser(undefined)
        }
        setOpen((prev) => !prev)
    }

    const handleSetPage = (page: number) => {
        setPage(page)
    }

    const { isLoading, isRefetching } = useQueryHook({
        queryName: 'users',
        queryRoute: `/user/`,
        params: [
            ['offset', (page - 1) * 10],
            ['limit', 10],
            ['role', role],
            ['search', debouncedSearchValue],
        ],
        options: {
            onSuccess: (response: any) => {
                setQuestionAdmin(response.data.results)
                setCount(response.data.count)
            },
            enabled: role !== "applicant"
        },
    });

    const { isLoading: isApplicantLoading, isRefetching: isApplicantRefetching } = useQueryHook({
        queryName: 'applicants',
        queryRoute: `/applicant/`,
        params: [
            ['offset', (page - 1) * 10],
            ['limit', 10],
            ['search', debouncedSearchValue],
            ['organization', searchOrganization],
            ['from', searchFrom],
            ['to', searchTo],
        ],
        options: {
            onSuccess: (response: any) => {
                setQuestionAdmin(response.data.results)
                setCount(response.data.count)
            },
            enabled: role === "applicant"
        },
    });

    const userLoading = isLoading || isRefetching;
    const applicantLoaing = isApplicantLoading || isApplicantRefetching


    const handleShowResultModal = (user: any) => {
        setResultUser({ ...user, ...user.profile });
        setShowResultModal(true);
    }

    const hideResultModal = () => {
        setResultUser(null);
        setShowResultModal(false)
    }


    const ExamTableHeaders = [
        [
            {
                key: 'first_name',
                title: 'First Name',
            },
            {
                key: 'last_name',
                title: 'Last Name',
            },
            ...(role === 'applicant'
                ? [
                    {
                        key: 'licence_number',
                        title: 'Licence Number',
                    },
                    {
                        key: 'created_at',
                        title: 'Attended at',
                        render: (user: any) => (
                            <span>{dayjs(user?.created_at).format('YYYY-MM-DD hh:mm A')}</span>
                        ),
                    },
                ]
                : []),
            ...(['applicant', 'invigilator'].includes(role)
                ? [
                    {
                        key: 'organization_name',
                        title: 'Organization',
                        render: (user: any) => (
                            <span>
                                {user.organization_name || user?.user?.organization_name}
                            </span>
                        )
                    },
                ]
                : []
            ),
            ...(['question_admin', 'invigilator', 'staff'].includes(role)
                ? [
                    {
                        key: 'email',
                        title: 'Email',
                    },
                    {
                        key: 'phone',
                        title: 'Phone',
                    },
                ]
                : []
            ),
            ...(role === 'applicant'
                ? [
                    {
                        key: 'result',
                        title: 'Result',
                        render: (user: any) => (
                            <>
                                {
                                    user?.active ?
                                        <span className="cursor-default capitalize p-2 bg-yellow-400">
                                            On going
                                        </span> :
                                        <span>
                                            {getPercentage(user.obtained_marks, user.total_marks)}
                                        </span>
                                }
                            </>
                        ),
                    },
                ]
                : []),

            {
                key: 'actions',
                title: 'Action',
                render: (user: any) => (
                    <>
                        <span className="flex gap-4 items-center">
                            {role === "applicant" &&
                                <>
                                    {
                                        !user.active &&
                                        <Button
                                            icon={<FaPrint />}
                                            varient="outline-primary"
                                            onClick={() => handleShowResultModal(user)}
                                        />
                                    }
                                    {
                                        !user.active &&
                                        <Button
                                            icon={<FaEye />}
                                            varient='outline-complimentary'
                                            onClick={() => navigate(`/super-admin/applicant/${user.user.id}/profile/${user.id}/preview`)}
                                        />
                                    }
                                </>

                            }
                            {role !== 'applicant' && (
                                <>

                                    <Action.Edit
                                        onClick={() => {
                                            setOpen(true)
                                            setUser(user)
                                        }}
                                    />
                                </>

                            )}

                            <DeleteButton id={user.user?.id || user.id} />
                        </span>
                    </>
                ),
            },
        ],
    ];

    const handleSearchChange = (value: string) => setSearch(value);

    return (
        <SuperAdminLayout>
            <div className="flex justify-between items-center">
                <h2 className="text-3xl text-gray-800 font-bold">Users</h2>
                <Button
                    icon={<IoMdAdd />}
                    label="Add User"
                    onClick={() => handleOpenUserForm()}
                />
            </div>

            {open && (
                <div>
                    <UserForm
                        key={user ? user.id : ''}
                        hideForm={handleOpenUserForm}
                        user={user}
                        activeTab={role}
                    />
                </div>
            )}

            <div className="w-full flex justify-between items-center text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                    {tabs.map((tab) => (
                        <li className="me-2" key={tab.key}>
                            <span
                                className={`uppercase text-lg font-bold inline-block p-4 border-b-2 border-transparent rounded-t-lg ${role === tab.key
                                    ? 'hover:text-blue-600 hover:border-blue-300 text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500'
                                    : 'hover:text-gray-600 hover:border-gray-300 text-gray-600 border-gray-600 dark:text-gray-500 dark:border-gray-500 cursor-pointer'
                                    }`}
                                onClick={() => tab.onClick(tab.key)}
                            >
                                {tab.name}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="w-full overflow-auto">
                <div className='py-4 flex items-center justify-between'>
                    <div className='flex items-center gap-6'>
                        <SearchInput
                            value={search}
                            placeholder='Search user'
                            onChange={handleSearchChange}
                        />

                        {
                            role === "applicant" &&
                            <>
                                <DateInput
                                    value={searchFrom}
                                    label="From" onChange={handleFromChange} />

                                <DateInput label="To"
                                    value={searchTo}
                                    onChange={handleToChange} />

                                <OrganizationSelect
                                    onChange={handleOrganizationChange} />
                            </>
                        }
                    </div>
                    {
                        role === "applicant" &&
                        <DownloadBtn
                            key={`${searchFrom}_${searchTo}_${searchOrganization}`}
                            link="/reports/"
                            params={{
                                from: searchFrom,
                                to: searchTo,
                                organization: searchOrganization
                            }}
                        />
                    }
                </div>

                <CustomTable
                    headers={ExamTableHeaders}
                    data={questionAdmin}
                    callerId={0}
                    isLoading={userLoading || applicantLoaing}
                />
            </div>
            <div>
                <Pagination
                    total={count}
                    perPage={10}
                    setPage={handleSetPage}
                    page={page}
                />
            </div>
            {
                resultUser &&
                <ResultModal
                    show={showResultModal}
                    user={resultUser}
                    hideModal={hideResultModal}
                />
            }
        </SuperAdminLayout>
    )
}

const DeleteButton = ({ id }: { id: number }) => {
    const queryClient = useQueryClient()

    const { mutate: deleteUser } = useMutationHook({
        queryRoute: `/user/${id}/`,
        method: 'delete',
        options: {
            onSuccess: () => {
                queryClient.invalidateQueries(['users'])
                queryClient.invalidateQueries(['applicants'])
            },
            onError: (err) => {
                toast.error(err.detail)
            },
        },
    })

    return (
        <Action.Delete
            onClick={() => {
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'You want to delete this user!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I am sure!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        deleteUser({})
                    }
                })
            }}
        />
    )
}

const OrganizationSelect = ({ onChange }: IOrganizationProps) => {
    const { data: organizations } = useQueryHook({
        queryName: "organziation",
        queryRoute: "/organizations/",
    });
    const airlines = (organizations?.data?.results ?? []).map((organization: IOrganization) => ({ label: organization.name, value: organization.id }));

    const handleSelectChange = (value: SingleValue<{ label: string, value: number }>) => {
        if (value) onChange(value.value)
    };
    return (
        <div className='relative text-gray-600 min-w-[200px]'>
            <span className='z-10 absolute bg-white text-sm top-[-14px] left-0.5 px-2'>Organization</span>
            <Select
                options={airlines}
                onChange={handleSelectChange}
                closeMenuOnSelect={true}
            />
        </div>
    )
}