import { Link, useNavigate } from "react-router-dom";

//
import Button from "../../components/Button";
import BackButton from "../../components/BackButton";
import NoContentSection from "../../components/NoContentSection";

//
import SuperAdminLayout from "../../layout/SuperAdminLayout";
import { useQueryHook } from "../../hooks/react-query/useQueryHook";

//
import { IOrganization } from "../../types";

//
import Image from "../../elements/Image";

//
import { IoMdAdd } from "react-icons/io";
import { VscLoading } from "react-icons/vsc";

/**
 * 
 */
export default function OrganizationListing() {
    const navigate = useNavigate();

    const handleAddOrganizationBtnClick = () => {
        navigate('/super-admin/organizations/create')
    }

    const { data: response, isLoading, isRefetching } = useQueryHook({
        queryName: "organizations-listing",
        queryRoute: '/organizations/'
    });

    return (
        <SuperAdminLayout>
            <div className="flex justify-between items-center mb-8">
                <div className='flex group'>
                    <BackButton />
                    <h2 className="text-3xl font-bold text-gray-800">Organizations</h2>
                </div>
                <Button
                    icon={<IoMdAdd />}
                    label="Add Organization"
                    onClick={() => handleAddOrganizationBtnClick()}
                />
            </div>

            <div>
                {
                    isLoading || isRefetching ?
                        <div className="min-h-[40vh] flex justify-center items-center">
                            <VscLoading className="animate-spin text-primary-200" fontSize={48} />
                        </div>
                        :
                        response?.data?.results &&
                            response.data.results.length ?
                            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
                                {response.data.results.map((organization: IOrganization) => {
                                    return (
                                        <Link to={`/super-admin/organizations/${organization.id}`} className="w-[250px] mx-auto hover:shadow-md py-6" key={organization.id}>
                                            <div className="rounded-lg block p-4 mb-2 "  >
                                                <div className="w-full h-[200px] flex items-center justify-center">
                                                    <Image className="w-full h-full object-contain rounded overflow-hidden" src={organization.icon} alt={organization.name} />
                                                </div>
                                            </div>
                                            <div className="text-center font-semibold text-xl px-4 text-gray-800">
                                                {organization.name}
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                            :
                            <NoContentSection title="Organizations" />
                }
            </div>
        </SuperAdminLayout>
    )
}