import { FaBoxOpen } from "react-icons/fa";

//
import { INoContentProps } from "../types";

/**
 * 
 */
export default function NoContentSection({ title }: INoContentProps) {
    return (
        <div className="min-h-[400px] w-full flex flex-col justify-center items-center text-gray-600 bg-blue-50">

            <FaBoxOpen fontSize="120" />

            <h4 className="text-2xl mt-4">
                No {title} Found.
            </h4>
        </div>
    )
}