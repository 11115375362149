//
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

//
import { handleClearCaller } from "../../_redux/socket";
import { useAppDispatch, useAppSelector } from "../../_redux/redux";
import { useQueryHook } from "../../hooks/react-query/useQueryHook";

//
import Header from "../../components/Header";
import Button from "../../components/Button";
import Pagination from "../../components/Pagination";
import DownloadBtn from "../../components/DownloadBtn";
import ResultModal from "../../components/ResultModal";
import CustomTable from "../../components/CustomTable";
import TableDataTimer from "../../components/TableDataTimer";

//
import { IStudentRegister } from "../../types";

//
import { FaEye } from "react-icons/fa";
import { DateInput } from "../../components/DateInput";

/**
 * 
 */
export default function ExamListingPage() {
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();
    const caller: any = useAppSelector(state => state.socket.caller);
    const todayDate = dayjs().format('YYYY-MM-DD');


    const [searchFrom, setSearchFrom] = useState(todayDate);
    const [searchTo, setSearchTo] = useState(todayDate);

    const [showResultModal, setShowResultModal] = useState(false);
    const [resultUser, setResultUser] = useState<IStudentRegister | null>(null);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [ongoingStudents, setOngoingStudents] = useState([]);

    const examStarted: any = useAppSelector(state => state.socket.examStudentInfo);
    const user: any = useAppSelector(state => state.user.user);

    useEffect(() => {
        if (examStarted?.user) {
            queryClient.invalidateQueries(['ongoingStudents']);
        }
    }, [examStarted, examStarted?.user, queryClient])

    useQueryHook({
        queryName: 'ongoingStudents',
        queryRoute: `/applicant/`,
        params: [
            ['offset', (page - 1) * 10],
        ],
        options: {
            onSuccess: (response: any) => {
                setOngoingStudents(response.data.results)
                setCount(response.data.count)
            }
        },
    })

    const handleCallerCall = () => {
        const callerStudent: any = ongoingStudents.find((std: any) => std?.user?.id === caller.id);

        Swal.fire({
            title: 'Student is requesting your assistance.',
            text: `${callerStudent.first_name} is at Seat ${callerStudent?.seat_plan}`,
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(handleClearCaller())
            }
        })
    };

    const handleShowResultModal = (student: any) => {
        setResultUser({ ...student, ...student.user });
        setShowResultModal(true);
    }
    const ExamTableHeaders = [
        [
            {
                key: 'seat_plan',
                title: 'Seat Plan',
            },
            {
                key: 'name',
                title: 'Full Name',
                render: (student: IStudentRegister) => `${student?.first_name} ${student?.last_name}`,
            },
            {
                key: 'licence_number',
                title: 'Licence number',
            },
            {
                key: 'organization',
                title: 'Organization',
                render: (student: any) => `${student?.user?.organization_name}`,
            },
            {
                key: 'licence_type',
                title: 'Licence type',
            },
            {
                key: 'end_time',
                title: 'Remaining time',
                render: (student: any) => <TableDataTimer key={student.active} isRunning={student.active} endTime={student.end_time} />
            },
            {
                key: 'actions',
                title: 'Action',
                render: (student: any) => (
                    <span className="flex gap-4 items-center">
                        {
                            caller?.id === student?.user?.id &&
                            <span
                                className="border-2 relative rounded py-2 px-3 flex items-center cursor-pointer font-bold"
                                style={{ columnGap: '0.5rem' }}
                                onClick={handleCallerCall}
                            >
                                Accept
                                <span className="absolute right-[-7px] top-[-5px]">
                                    <span className="relative flex h-3 w-3">
                                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                                        <span className="relative inline-flex rounded-full h-3 w-3 bg-white"></span>
                                    </span>
                                </span>
                            </span>
                        }

                        {
                            !student.active &&
                            <Button
                                icon={<FaEye />}
                                varient="outline-primary"
                                onClick={() => handleShowResultModal(student)}
                            />
                        }
                    </span>
                ),
            }
            ,
        ],
    ];

    const hideResultModal = () => {
        setResultUser(null);
        setShowResultModal(false)
    }

    const handleSetPage = (page: number) => {
        setPage(page)

    }

    const handleFromChange = (data: string) => setSearchFrom(data);
    const handleToChange = (data: string) => setSearchTo(data);

    return (
        <div className="min-h-screen bg-light-200">
            <Header />
            <div className="flex flex-col justify-center items-start p-10 mt-10 bg-light-100 mx-auto w-[90%] sm:w-[540px] md:w-[540px] lg:w-[760px] xl:w-[1200px]">
                <div className="flex w-full justify-between items-center">
                    <h2 className="text-3xl text-gray-800 font-bold">Examinations</h2>

                    <div className='pt-4 flex items-center justify-between gap-8'>
                        <div className='flex items-center gap-6'>
                            <DateInput
                                label="From"
                                value={searchFrom}
                                onChange={handleFromChange} />

                            <DateInput
                                label="To"
                                value={searchTo}
                                onChange={handleToChange} />
                        </div>

                        <DownloadBtn
                            key={`${searchFrom}_${searchTo}_${user.organization}`}
                            link="/reports/"
                            params={{
                                from: searchFrom,
                                to: searchTo,
                                organization: user.organization
                            }}
                        />
                    </div>
                </div>
                <div className="w-full mt-4 overflow-auto">
                    <CustomTable
                        key={examStarted?.user}
                        headers={ExamTableHeaders}
                        data={ongoingStudents}
                        callerId={caller?.id}
                    />
                </div>

                <div>
                    <Pagination
                        total={count}
                        perPage={10}
                        setPage={handleSetPage}
                        page={page}
                    />
                </div>
            </div>
            {
                resultUser &&
                <ResultModal
                    show={showResultModal}
                    user={resultUser}
                    hideModal={hideResultModal}
                />
            }
        </div>
    )
}

