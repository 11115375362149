import Image from '../elements/Image'

//
import Logo from '../assets/logo.png'
import { useAppSelector } from '../_redux/redux'

/**
 *
 */
export default function ExamHeader() {
    const user = useAppSelector((state) => state.user.user)
    return (
        <div className="h-[80px] px-10 py-3 flex items-center justify-between bg-white w-full">
            <h1 className="font-bold text-2xl text-gray-700 text-center capitalize">
                Airline Pilot Assessment
            </h1>

            <Image src={Logo} alt="geeta-aviation" className="h-full py-1" />

            {user && (
                <div className="font-semibold text-gray-700 text-lg capitalize">
                    {user.first_name} {user.last_name}
                </div>
            )}
        </div>
    )
}
