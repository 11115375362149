import * as yup from 'yup'

const loginSchema = yup
    .object({
        username: yup.string().required(),
        password: yup.string().required(),
    })
    .required()

const studentRegisterSchema = yup
    .object({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        date_of_birth: yup.string().required(),
        licence_type: yup.string().required(),
        type: yup.string().required(),
        number: yup.number().required(),
        validity: yup.string().required(),
        country: yup.string().required(),
        organization: yup.number().required(),
    })
    .required()

const categorySchema = yup
    .object({
        title: yup.string().required(),
        for_test: yup.boolean(),
        count: yup.number(),
    })
    .required()

const questionSchema = yup
    .object({
        questions: yup
            .array()
            .of(
                yup.object({
                    question: yup.string().required('Question is required'),
                    options: yup
                        .array()
                        .of(
                            yup.object({
                                option: yup
                                    .string()
                                    .required('Option is required'),
                                is_correct: yup.boolean(),
                            })
                        )
                        .required(),
                })
            )
            .min(1)
            .required(),
    })
    .required()

const userSchema = (isEdit: Boolean) =>
    yup
        .object({
            first_name: yup.string().required(),
            last_name: yup.string().required(),
            username: yup.string().email().required(),
            role: yup.string().required(),
            phone: yup.string(),
            organization: yup.string(),
            password: isEdit ? yup.string() : yup.string().required(),
            assigned_categories: yup.array().of(yup.number()),
        })
        .required()

const examScheduleSchema = yup
    .object({
        start_date: yup.string().required(),
        start_time: yup.string().required(),
        end_time: yup.string().required(),
        organization: yup.string().required(),
    })
    .required()

export default loginSchema

export {
    loginSchema,
    studentRegisterSchema,
    categorySchema,
    questionSchema,
    userSchema,
    examScheduleSchema,
}
