import MathOption from '../../components/MathOption';


/**
 * 
 */
export default function QuestionPage() {
  return (
    <div>
      <MathOption />
    </div>
  )
}