import { useState } from "react";
import { useDebounce } from 'use-debounce';

//
import BackButton from "../../components/BackButton";
import SearchInput from "../../components/SearchInput";
import QuestionSearch from "../Category/QuestionSearch";

//
import AdminLayout from "../../layout/AdminLayout";

export default function Questions() {
    const [questionSearch, setQuestionSearch] = useState<string>('');
    const handleSearchChange = (value: string) => setQuestionSearch(value);

    const [debouncedQuestionSearch] = useDebounce(questionSearch, 1000);
    return (
        <AdminLayout>
            <>
                <div className="flex justify-between items-center mb-6">
                    <div className='flex group'>
                        <BackButton />
                        <h2 className="text-3xl font-bold text-gray-800">Questions</h2>
                    </div>
                    <div>
                        <SearchInput
                            value={questionSearch}
                            placeholder='Search questions'
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>

                <QuestionSearch questionSearch={debouncedQuestionSearch} />
            </>
        </AdminLayout>
    )
}