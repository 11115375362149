import { useEffect, useState } from 'react'
import { IOptionProps } from '../types'

export default function Option({
    selectedOptions,
    option,
    handleSelectedOption,
}: IOptionProps) {
    const [isSelected, setIsSelected] = useState(false)

    const handleOptionClick = () => {
        handleSelectedOption(option.id)
    }

    useEffect(() => {
        setIsSelected(selectedOptions.includes(option.id))
    }, [option.id, selectedOptions])

    return (
        <div
            className={`cursor-pointer text-xl text-gray-800 border-2 py-3 px-4 flex items-center mb-4 ${isSelected ? 'bg-light-200' : ''
                }`}
            onClick={handleOptionClick}
        >
            <input
                id="link-checkbox"
                type="checkbox"
                className="w-4 h-4 text-primary-200 bg-gray-100 border-gray-300 rounded focus:ring-primary-300 dark:focus:ring-primary-200 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-4"
                checked={isSelected}
                readOnly
            />

            {option.option}
        </div>
    )
}
