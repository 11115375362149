import { toast } from 'react-toastify';
import { useBlocker, useNavigate } from 'react-router';
import { Dialog, Transition } from '@headlessui/react';
import { useAppDispatch, useAppSelector } from '../_redux/redux';
import { Fragment, useCallback, useEffect, useState } from 'react';

//
import Button from '../components/Button';
import ExamHeader from '../components/ExamHeader'
import ExamSidebar from '../components/ExamSidebar'
import LoadingModal from '../components/LoadingModal';

//
import { handleSetResult } from '../_redux/exam';
import { useMutationHook } from '../hooks/react-query/useQueryHook';

//
import { IExamLayoutProps } from '../types'
import { LOCAL_HAS_EXAM_EFFECT_RAN, LOCAL_HAS_REFRESHED_EXAM } from '../constants';

//
import { AiOutlineExclamationCircle } from "react-icons/ai";

/**
 * 
 */
export default function ExamLayout({ children }: IExamLayoutProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    //
    const answers = useAppSelector((state) => state.exam.answers);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => {
            if (nextLocation.pathname === "/applicant/result") return false;
            return currentLocation.pathname !== nextLocation.pathname
        }
    );

    const [showExitConfirmationModal, setShowExitConfirmationModal] = useState(false);

    const { mutate: handleSubmitAnswers, isLoading } = useMutationHook({
        queryRoute: '/create-answers/',
        options: {
            onSuccess: (response: any) => {
                dispatch(handleSetResult(response.data))
                navigate('/applicant/result')
            },
            onError: (errors) => {
                errors.map((error: string) => toast.error(error))
            },
        },
    });

    useEffect(() => {
        if (blocker.state === "blocked") {
            setShowExitConfirmationModal(true);
        }
    }, [blocker.state]);

    useEffect(() => {
        const hasUserRefreshed = localStorage.getItem(LOCAL_HAS_REFRESHED_EXAM);
        if (hasUserRefreshed === "true") {
            handleSubmitAnswers({ options: [] })
        }
    }, [handleSubmitAnswers])

    const handleExamEnd = useCallback(() => {
        let answersArr = Object.values(answers).flat()
        handleSubmitAnswers({ options: answersArr })
    }, [answers, handleSubmitAnswers]);

    useEffect(() => {
        const effectRan = localStorage.getItem(LOCAL_HAS_EXAM_EFFECT_RAN);
        const handleBeforeUnload = (event: any) => {
            event.preventDefault();

            event.resultValue = "Show dialog";
        };


        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);

            if (effectRan === 'true') {
                localStorage.setItem(LOCAL_HAS_REFRESHED_EXAM, 'true');
            }
            localStorage.setItem(LOCAL_HAS_EXAM_EFFECT_RAN, 'true');
        };
    }, []);


    const handleHideModal = (proceed: boolean) => {
        if (proceed) {
            handleExamEnd();

            if (blocker.proceed) {
                blocker.proceed();
            }
        }
        else {
            if (blocker.reset) {
                blocker.reset();
            }
        }
        setShowExitConfirmationModal(false);
    };

    return (
        <div className="min-h-screen w-full flex bg-light-200"
            onContextMenu={(event) => event.preventDefault()}
        >
            <ExamSidebar />

            <div className="w-full">
                <ExamHeader />

                <div>{children}</div>
            </div>

            <ConfirmExitNavigation show={showExitConfirmationModal} hideModal={handleHideModal} />
            <LoadingModal show={isLoading} />
        </div >
    )
}

const ConfirmExitNavigation = ({ show, hideModal }: any) => {
    const handleSubmitAndQuit = () => {
        hideModal(true);
    }
    const handleCancel = () => {
        hideModal();
    }
    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={hideModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="transform overflow-hidden rounded bg-white p-12 text-left align-middle shadow-xl transition-all">
                                <AiOutlineExclamationCircle fontSize={140} className="mx-auto text-orange-500 opacity-70" />
                                <h3 className='text-3xl font-medium break-words text-gray-800 text-center pt-4'>
                                    Are you sure you want to leave?
                                </h3>

                                <p className='font-normal max-w-[300px] mx-auto text-gray-800 text-center mt-6 mb-1'>
                                    Click "YES" if you wish to end your exam and submit your answers.
                                </p>

                                <div className='w-full flex justify-around items-center mt-5'>
                                    <Button
                                        label='NO'
                                        varient='outline-primary'
                                        onClick={handleCancel}
                                    />

                                    <Button
                                        label='YES'
                                        onClick={handleSubmitAndQuit}
                                        varient='secondary'
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>)
}
