import { IDateProps } from "../types";


export const DateInput = ({ label, value, onChange }: IDateProps) => {
    const maxDate: string = new Date().toISOString()?.slice(0, 10);

    const handleDateChange = (e: React.FormEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
    };

    return (
        <div className='relative border-2 py-1 px-2 text-gray-600'>
            <span className='absolute bg-white text-sm top-[-14px] left-0.5 px-2 capitalize'>{label}</span>
            <input
                className='outline-0'
                type="date"
                onChange={handleDateChange}
                value={value}
                max={maxDate}
            />
        </div>
    )
}
