import { VscLoading } from "react-icons/vsc";

/**
 * 
 */
export default function LoadingPage() {
    return (
        <div className="min-h-screen w-full flex justify-center items-center">
            <VscLoading className="animate-spin" fontSize={58} />
        </div>
    )
}