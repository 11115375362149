import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { persistStore } from 'redux-persist'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import { QueryClient, QueryClientProvider } from 'react-query'

//
import App from './App'

//
import { store } from './_redux/store'

//
import './index.css'
import 'react-toastify/dist/ReactToastify.css'

//
import WebSocketProvider from './webSocket/WebSocketContextProvider'
import LoadingPage from './pages/LoadingPage'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { refetchOnWindowFocus: false },
    },
})

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <WebSocketProvider>
                    <PersistGate loading={<LoadingPage />} persistor={persistor}>
                        <App />
                    </PersistGate>
                </WebSocketProvider>
                <ToastContainer />
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>
)
