import { Navigate, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import AuthLayout from '../layout/AuthLayout/AuthLayout'
import { IRequireAuthProps } from '../types'
import { defaultRouteForUser } from '../constants'

function RequireAuth({ allowedRoles }: IRequireAuthProps) {
    const location = useLocation()
    const {
        token,
        role,
    }: {
        token?: string | undefined | null
        role?: 'staff' | 'applicant' | 'invigilator'
    } = useAuth()

    if (!token || !role) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    const content = allowedRoles.includes(role) ? (
        <AuthLayout />
    ) : (
        <Navigate
            to={defaultRouteForUser[role]}
            state={{ from: location }}
            replace
        />
    )

    return content
}

export default RequireAuth
