import { createBrowserRouter, RouterProvider } from 'react-router-dom'

//
import RequireAuth from './route_handlers/RequireAuthWrapper'
import ProtectedRoute from './route_handlers/ProtectedRoute'
import LoginPage from './pages/Login'
import StudentRegister from './pages/StudentRegister'
import ExamStart from './pages/ExamStartPage/ExamStart'
import ExamPage from './pages/Exam'
import { CategoryListing } from './pages/Category'
import Category from './pages/Category/Category'
import ResultPage from './pages/Result'
import QuestionPage from './pages/Question'
import QuestionFrom from './pages/Category/CategoryQuestionForm'
import UserPage from './pages/User'
import OngoingExamListingPage from './pages/OngoingExamListing'
import OrganizationListing from './pages/OrganizationListing'
import OrganizationForm from './pages/OrganizationForm'
import ExamScheduleListingPage from './pages/ExamSchedule'
import ApplicantResultPreview from './pages/ApplicantResultPreview'
import Questions from './pages/Questions'
/**
 *
 */
const router = createBrowserRouter([
    {
        path: '/',
        children: [
            {
                element: <RequireAuth allowedRoles={['staff']} />,
                children: [
                    {
                        path: '',
                        element: <StudentRegister />,
                    },
                ],
            },
        ],
    },
    {
        path: '/invigilator',
        children: [
            {
                element: <RequireAuth allowedRoles={['invigilator']} />,
                children: [
                    {
                        path: 'exam-listing',
                        element: <OngoingExamListingPage />,
                    },
                ],
            },
        ],
    },
    {
        path: '/applicant',
        children: [
            {
                element: <RequireAuth allowedRoles={['applicant']} />,
                children: [
                    {
                        path: '',
                        element: <ExamStart />,
                    },
                    {
                        path: 'exam',
                        element: <ExamPage />,
                    },
                    {
                        path: 'result',
                        element: <ResultPage />,
                    },
                ],
            },
        ],
    },
    {
        path: '/admin',
        children: [
            {
                element: <RequireAuth allowedRoles={['question_admin']} />,
                children: [
                    {
                        path: 'category',
                        element: <CategoryListing />,
                    },
                    {
                        path: 'category/:id',
                        element: <Category />,
                    },
                    {
                        path: 'questions/:category_id',
                        element: <QuestionPage />,
                    },
                    {
                        path: 'questions/:category_id/create',
                        element: <QuestionFrom />,
                    },
                    {
                        path: 'questions/:category_id/edit/:question_id',
                        element: <QuestionFrom />,
                    },
                ],
            },
        ],
    },
    {
        path: '/super-admin',
        children: [
            {
                element: <RequireAuth allowedRoles={['superuser']} />,
                children: [
                    {
                        path: 'users',
                        element: <UserPage />,
                    },
                    {
                        path: 'applicant/:id/profile/:profileId/preview',
                        element: <ApplicantResultPreview />,
                    },
                    {
                        path: 'exam-schedule',
                        element: <ExamScheduleListingPage />,
                    },
                    {
                        path: 'organizations',
                        element: <OrganizationListing />,
                    },
                    {
                        path: 'organizations/:form',
                        element: <OrganizationForm />,
                    },
                    {
                        path: 'category',
                        element: <CategoryListing />,
                    },
                    {
                        path: 'category/:id',
                        element: <Category />,
                    },
                    {
                        path: 'questions',
                        element: <Questions />,
                    },
                    {
                        path: 'questions/:category_id',
                        element: <QuestionPage />,
                    },
                    {
                        path: 'questions/:category_id/create',
                        element: <QuestionFrom />,
                    },
                    {
                        path: 'questions/:category_id/edit/:question_id',
                        element: <QuestionFrom />,
                    },
                ],
            },
        ],
    },
    {
        path: '/',
        element: <ProtectedRoute />,
        children: [
            {
                path: 'login',
                element: <LoginPage />,
            },
        ],
    },
])

function App() {
    return <RouterProvider router={router} />
}

export default App
