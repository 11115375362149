import { allowedRolesType } from '../types';
import rules from './roles-rules';

export const checkPermission = (role: allowedRolesType | undefined, action: string) => {
	if (!role) return false;

	const permissions = rules[role];
	if (!permissions) {
		// role is not present in the rules
		return false;
	}

	const staticPermissions = permissions.static;

	if (staticPermissions && staticPermissions.includes(action)) {
		// static rule not provided for action
		return true;
	}

	return false;
};