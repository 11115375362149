import { useState } from 'react'

//
import {
    useQueryHook,
} from '../../hooks/react-query/useQueryHook'
import { ICategoryQuestionListing, IQuestion } from '../../types'

//
import { useAppSelector } from '../../_redux/redux'

//
import QuestionItem from './QuestionItem'
import Pagination from '../../components/Pagination'
import NoContentSection from '../../components/NoContentSection'

/**
 * 
 */
export default function CategoryQuestionListing({
    id,
}: ICategoryQuestionListing) {
    const [questions, setQuestions] = useState<IQuestion[] | []>([])
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const user = useAppSelector(state => state.user.user);

    const role = user?.role === "superuser" ? "super-admin" : "admin";

    const handleSetPage = (page: number) => {
        setPage(page)
    }

    const handleFetchCategoryQuestionSuccess = (response: any) => {
        setQuestions(response.data.results)
        setCount(response.data.count)
    }

    useQueryHook({
        queryName: `questions-${id}`,
        queryRoute: `/questions/`,
        params: [
            ['offset', (page - 1) * 10],
            ['category', id],
            ['limit', 10],
        ],
        options: {
            onSuccess: handleFetchCategoryQuestionSuccess,
        },
    })

    return (
        <div className="w-full pt-4">
            <div className=" w-full rounded-2xl bg-white ">
                {questions.length > 0 ?
                    questions.map((question: IQuestion) => (
                        <QuestionItem
                            key={question.id}
                            question={question}
                            id={id}
                            role={role}
                        />
                    )) :
                    <NoContentSection title='Questions' />
                }
            </div>
            <Pagination
                total={count}
                perPage={10}
                setPage={handleSetPage}
                page={page}
            />
        </div>
    )
}
