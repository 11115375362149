import { useMemo } from "react";
import { IExamProgressProps } from "../types";
import ExamProgressItem from "./ExamProgressItem";
import { useAppSelector } from "../_redux/redux";

export default function ExamProgress({ total, current }: IExamProgressProps) {
    const answers = useAppSelector(state => state.exam.answers);

    const totalCount = useMemo(
        () =>
            Array.from({ length: total }, (v, i) => i + 1),
        [total]
    )


    return (
        <div className="flex w-full flex-wrap gap-4">
            {
                totalCount.map((_, idx) => {
                    let completed = Boolean(answers[idx]) ? answers[idx].length ? true : false : false;
                    let active = idx === current;
                    return (
                        <ExamProgressItem key={idx} page={idx + 1} active={active} completed={completed} />
                    )
                })
            }
        </div>
    )
}