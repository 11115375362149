import { MathJax, MathJaxContext } from 'better-react-mathjax'

const ascii = '`sum_(n = 100)^(1000)(frac(10sqrt(n))(n))`'

const config = {
    loader: {
        load: ['input/asciimath'],
        asciimath: {
            displaystyle: true,
            delimiters: [
                ['$', '$'],
                ['`', '`'],
            ],
        },
    },
}

/**
 *
 */
export default function MathOption() {
    return (
        <div>
            <MathJaxContext config={config}>
                <h2>Basic MathJax example with AsciiMath</h2>
                <MathJax hideUntilTypeset={'first'}>
                    <p style={{ textAlign: 'center' }}>
                        `sum_(n = 100)^(1000)(frac(10sqrt(n))(n))`
                    </p>
                    {ascii}
                </MathJax>
            </MathJaxContext>
        </div>
    )
}
