export const LOCAL_ACCESS_TOKEN_NAME = 'ga_user'
export const LOCAL_REFRESH_TOKEN_NAME = 'ga_userRefresh'
export const LOCAL_HAS_REFRESHED_EXAM = 'ga_user_refreshed'
export const LOCAL_HAS_EXAM_EFFECT_RAN = 'ga_exam_effect_ran'

export const PERSIST = 'ga_persist'

export const airlines = [
    {
        label: 'Yeti Airlines',
        value: 1,
    },
]

export const QUIZ_TYPE = [
    { label: 'Single Answer Question', value: 'single' },
    { label: 'Multiple Answer Question', value: 'multiple' },
]

export const AIRCRAFT_TYPE = [
    {
        label: 'Fixed Wing',
        value: 'fixed_wing',
    },
    {
        label: 'Rotary Wing',
        value: 'rotatry_wing',
    },
]

export const LICENSE_TYPE = [
    {
        label: 'CPL',
        value: 'CPL',
    },
    {
        label: 'ATPL',
        value: 'ATPL',
    },
]

export const ROLE_NAME: { [key: string]: string } = {
    invigilator: 'Invigilator',
    staff: 'Staff',
    question_admin: 'Question Admin',
}

export const USER_ROLES = [
    {
        label: 'Invigilator',
        value: 'invigilator',
    },
    {
        label: 'Staff',
        value: 'staff',
    },
    {
        label: 'Question Admin',
        value: 'question_admin',
    },
]

export const defaultRouteForUser = {
    applicant: '/applicant',
    staff: '/',
    invigilator: '/invigilator/exam-listing',
    question_admin: '/admin/category',
    superuser: '/super-admin/users',
}

export const PASS_PERCENTAGE = 70
export const TIMELIMIT = 80
export const DANGER_TIME_IN_SECOND = 900
