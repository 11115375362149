import Button from "./Button"

//
import { IoMdDownload } from "react-icons/io"

//
import { IDownloadBtnProps } from "../types"
import { useQueryHook } from "../hooks/react-query/useQueryHook"
import { useState } from "react"

/**
 * 
 */
export default function DownloadBtn({ link, params = {} }: IDownloadBtnProps) {
    const [enableDownload, setEnabelDownload] = useState<boolean>(false);
    const queryParams: any = Object.entries(params);
    const handleOnDownloadSuccess = (response: any) => {
        let pdfLink = response.data.path;
        const a = document.createElement('a');
        a.href = pdfLink;
        a.target = "_blank"
        a.rel = "noreferrer"
        a.download = 'document';
        a.click();
        setEnabelDownload(false);
    }

    const { isLoading } = useQueryHook({
        queryName: "/reports/",
        queryRoute: "/reports/",
        params: queryParams,
        options: {
            onSuccess: handleOnDownloadSuccess,
            enabled: enableDownload
        }
    })

    const onPdfDownloadBtnClick = () => {
        setEnabelDownload(true);
    }

    return (
        <Button
            varient='outline-secondary'
            icon={<IoMdDownload fontSize={20} />}
            label='Pdf'
            onClick={onPdfDownloadBtnClick}
            isLoading={isLoading}
        />
    )
}