import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'
import Swal from 'sweetalert2'

//
import { ICategory } from '../../types'
import AdminLayout from '../../layout/AdminLayout'
import Button from '../../components/Button'

import {
    useMutationHook,
    useQueryHook,
} from '../../hooks/react-query/useQueryHook'
import CategoryQuestion from './CategoryQuestionListing'

//
import { IoMdAdd } from 'react-icons/io'
import { AiOutlineEdit } from 'react-icons/ai'
import { MdDeleteOutline } from 'react-icons/md'

import CategoryForm from './CategoryForm'
import { checkPermission } from '../../roles/check-roles'
import { useAppSelector } from '../../_redux/redux'
import BackButton from '../../components/BackButton'

/**
 * 
 */
export default function Category() {
    const { id } = useParams();

    const user: any = useAppSelector(state => state.user.user);
    const queryClient = useQueryClient()
    const [category, setCategory] = useState<ICategory>()
    const navigate = useNavigate()
    const role = user?.role === "superuser" ? "super-admin" : "admin";

    const [open, setOpen] = useState(false)

    const handleCategoryEdit = () => {
        setOpen((prev) => !prev)
    }

    const handleFetchCategorySuccess = (response: any) => {
        setCategory(response.data)
    }

    useEffect(() => {
        if (user?.role === "question_admin" && id) {
            if (!user?.assigned_categories?.includes(+id)) {
                navigate('/admin/category');
            }
        }
    }, [id, navigate, user.assgined_categories, user?.assigned_categories, user?.role])

    useQueryHook({
        queryName: `category-${id}`,
        queryRoute: `/categories/${id}/`,
        options: {
            onSuccess: handleFetchCategorySuccess,
        },
    })

    const { mutate: deleteCategory, isLoading: isDeleteCategoryLoading } =
        useMutationHook({
            queryRoute: `/categories/${category?.id}/`,
            method: 'delete',
            options: {
                onSuccess: () => {
                    queryClient.invalidateQueries(['category-listing'])
                    navigate(`/${role}/category`);
                },
                onError: (err) => {
                    toast.error(err.detail)
                },
            },
        })

    const handleCategoryDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this category!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I am sure!',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCategory({})
            }
        })
    }

    const handleClose = () => setOpen(false);

    return (
        <AdminLayout>
            <>
                <div className="flex justify-between items-center mb-6">
                    <div className='flex items-center group'>
                        <BackButton />
                        <div className='text-gray-800'>
                            <h2 className="text-sm font-bold">Subject</h2>
                            <h3 className="text-4xl font-bold"> {category?.title}</h3>
                        </div>
                    </div>


                    {
                        checkPermission(user?.role, 'change:category') &&
                        <>
                            {!open && (
                                <div className="flex gap-4">
                                    <Button
                                        icon={<AiOutlineEdit />}
                                        label="Edit"
                                        onClick={handleCategoryEdit}
                                        disabled={isDeleteCategoryLoading}
                                        isLoading={isDeleteCategoryLoading}
                                    />

                                    <Button
                                        icon={<MdDeleteOutline />}
                                        label="Delete"
                                        varient="danger"
                                        disabled={isDeleteCategoryLoading}
                                        isLoading={isDeleteCategoryLoading}
                                        onClick={handleCategoryDelete}
                                    />
                                </div>
                            )}
                        </>
                    }


                </div>

                {open && (
                    <CategoryForm category={category} hideForm={handleClose} />
                )}

                <div>
                    <div className="flex justify-between items-center">
                        <div className="text-2xl font-bold">Questions listing</div>
                        <div>
                            <Button
                                icon={<IoMdAdd />}
                                label="Add Question"
                                onClick={() => {
                                    navigate(`/${role}/questions/${id}/create`)
                                }}
                            />
                        </div>
                    </div>
                    <div className=" max-h-[540px]  overflow-y-auto">
                        <CategoryQuestion id={id || ''} />
                    </div>
                </div>
            </>
        </AdminLayout>
    )
}
