import { jwtDecode } from 'jwt-decode';

//
import { handleSetRemainingTime } from '../_redux/exam';
import { handleSetCaller, handleSetExamStudentInfo } from '../_redux/socket';
import {
	applicantSocketEventType,
	invigilatorSocketEventType,
	publicSocketEventType,
} from './socketEventTypes';

/**
 * 
 */
export const PublicWebSocketEventHandler = (event: any, dispatch: any, role?: string) => {
	const eventData = JSON.parse(event.data);

	if (!role) return;

	try {
		let eventType = eventData.type;
		switch (eventType) {
			case publicSocketEventType.call_invigilator:
				dispatch(handleSetCaller(eventData.data));
				break;
			case publicSocketEventType.test_status:
				dispatch(handleSetExamStudentInfo(eventData.data));
				break;
			default:
				break;
		}
	} catch (err) {
		console.log(err);
	}
};

export const InvigilatorWebSocketEventHandler = (event: any, dispatch: any) => {
	const eventData = JSON.parse(event.data);

	try {
		let eventType = eventData.type;
		switch (eventType) {
			case invigilatorSocketEventType.call_invigilator:
				dispatch(handleSetCaller(eventData.data));
				break;
			case invigilatorSocketEventType.test_status:
				dispatch(handleSetExamStudentInfo(eventData.data));
				break;
			default:
				break;
		}
	} catch (err) {
		console.log(err);
	}
};

export const ApplicantWebSocketEventHandler = (event: any, dispatch: any, accessToken: string) => {
	const eventData = JSON.parse(event.data);
	if (accessToken) {
		const decodedToken: any = jwtDecode(accessToken);

		try {
			let eventType = eventData.type;
			switch (eventType) {
				case applicantSocketEventType.user_join:
					let user = eventData.user;
					if (decodedToken.username === user.username) {
						if (user.remaining_time) {
							dispatch(handleSetRemainingTime(user.remaining_time));
						}
					}
					break;
			}
		} catch (err) {
			console.log(err);
		}
	}
};