
//
import { useNavigate } from 'react-router-dom'
import { IButtonProps } from '../types'
import { IoMdArrowRoundBack } from 'react-icons/io';


/**
 * 
 */
export default function BackButton({
    className,
}: IButtonProps) {
    const navigate = useNavigate();
    let classes =
        'group-hover:static group-hover:opacity-100 absolute opacity-0 inline-flex items-center px-2 py-2 border-2 font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-gray-100 '

    const handleClick = () => {
        navigate(-1);
    }
    return (
        <button
            className={`mr-2 transition-colors  ${classes} ${className ?? ''}`}
            onClick={handleClick}
        >
            <IoMdArrowRoundBack fontSize={18} />
        </button>
    )
}
