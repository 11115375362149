import Swal from 'sweetalert2'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../_redux/redux'

//
import Button from './Button'
import Option from './Option'

//
import { IQuestionProps, OptionType } from '../types'
import { useMutationHook } from '../hooks/react-query/useQueryHook'

//
import {
    handleGetSelectedAnswer,
    handleNextQuestion,
    handlePreviousQuestion,
    handleSetAnswers,
    handleSetResult,
    handleSetSelectedOptions,
} from '../_redux/exam'

//
import LoadingModal from './LoadingModal'

/**
 * 
 */
export default function Question({
    question,
    totalQuestions,
}: IQuestionProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    //
    const selectedOptions = useAppSelector(
        (state) => state.exam.selectedOptions
    );
    const answers = useAppSelector((state) => state.exam.answers);
    const currentQuestion = useAppSelector(
        (state) => state.exam.currentQuestion
    );

    //
    useEffect(() => {
        dispatch(handleGetSelectedAnswer(currentQuestion));
    }, [currentQuestion, dispatch]);


    const { mutate: handleSubmitAnswers, isLoading } = useMutationHook({
        queryRoute: '/create-answers/',
        options: {
            onSuccess: (response: any) => {
                dispatch(handleSetResult(response.data))
                navigate('/applicant/result')
            },
            onError: (errors) => {
                errors.map((error: string) => toast.error(error))
            },
        },
    })

    const handleSelectedOption = (option: number) => {
        let tempSelectedOptions: number[] = [...selectedOptions]
        let selectedOptionIndex = tempSelectedOptions.findIndex(
            (arrayOption) => arrayOption === option
        )

        if (selectedOptionIndex < 0) {
            tempSelectedOptions = [option]
        } else {
            tempSelectedOptions = [];
        }
        dispatch(handleSetSelectedOptions(tempSelectedOptions))
        dispatch(
            handleSetAnswers({
                answers: tempSelectedOptions,
                questionIndex: currentQuestion,
            })
        )
    }

    const handleNextBtnClick = async () => {
        dispatch(
            handleSetAnswers({
                answers: selectedOptions,
                questionIndex: currentQuestion,
            })
        )
        dispatch(handleNextQuestion())
    }

    const handlePreviousBtnClick = () => {
        dispatch(
            handleSetAnswers({
                answers: selectedOptions,
                questionIndex: currentQuestion,
            })
        )
        dispatch(handlePreviousQuestion())
    }

    const handleSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to submit the Assessment!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, submit it!',
        }).then((result) => {
            if (result.isConfirmed) {
                let answersArr = Object.values(answers).flat()

                handleSubmitAnswers({ options: answersArr })
            }
        })
    }
    
    return (
        <div className="text-gray-800">
            <h3 className="text-xl mb-6">
                <span className="mr-2">{currentQuestion + 1}.</span>
                {question.question}
            </h3>

            <div>
                {question?.option_set?.map((option: OptionType) => (
                    <Option
                        selectedOptions={selectedOptions}
                        option={option}
                        key={option.id}
                        handleSelectedOption={handleSelectedOption}
                    />
                ))}
            </div>

            <div className="flex justify-between items-center mt-12">
                <Button
                    varient="outline-primary"
                    label="Previous"
                    onClick={handlePreviousBtnClick}
                    disabled={!Boolean(currentQuestion)}
                />
                {currentQuestion === totalQuestions - 1 ? (
                    <Button
                        label="Submit"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                ) : (
                    <Button label="Next" onClick={handleNextBtnClick} />
                )}
            </div>
            <LoadingModal show={isLoading} />
        </div>
    )
}