import { useMemo } from 'react'
import { IPagination } from '../types'

/**
 * 
 */
export default function Pagination({ total, page, setPage, perPage = 10 }: IPagination) {
    const totalCount = useMemo(
        () =>
            Array.from({ length: Math.ceil(total / perPage) }, (v, i) => i + 1),
        [perPage, total]
    )
    const lastPage = Math.ceil(total / perPage)

    return (
        <nav className="flex justify-center pt-4">
            <ul className="inline-flex -space-x-px text-sm">
                <li>
                    <button
                        disabled={page <= 1}
                        onClick={() => (page > 1 ? setPage(page - 1) : null)}
                        className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 "
                    >
                        Previous
                    </button>
                </li>

                {page < 5 ? (
                    totalCount
                        .filter(
                            (count, index) => index < 5 && index <= lastPage - 1
                        )
                        .map((count) => (
                            <li key={count}>
                                <button
                                    onClick={() => setPage(count)}
                                    className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${page === count
                                        ? ' bg-primary-200 text-white font-semibold hover:bg-primary-100 hover:text-white'
                                        : 'bg-white'
                                        }`}
                                >
                                    {count}
                                </button>
                            </li>
                        ))
                ) : (
                    <>
                        <li>
                            <button
                                onClick={() => setPage(page - 2)}
                                className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            >
                                {page - 2}
                            </button>
                        </li>

                        <li>
                            <button
                                onClick={() => setPage(page - 1)}
                                className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            >
                                {page - 1}
                            </button>
                        </li>

                        <li>
                            <button
                                className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-gray-100 border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                            >
                                {page}
                            </button>
                        </li>

                        {page + 1 <= lastPage && (
                            <li>
                                <button
                                    onClick={() =>
                                        page < totalCount.length
                                            ? setPage(page + 1)
                                            : null
                                    }
                                    className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
                                >
                                    {page + 1}
                                </button>
                            </li>
                        )}

                        {page + 2 <= lastPage && (
                            <li>
                                <button
                                    onClick={() =>
                                        page < totalCount.length
                                            ? setPage(page + 2)
                                            : null
                                    }
                                    className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 `}
                                >
                                    {page + 2}
                                </button>
                            </li>
                        )}
                    </>
                )}
                <li>
                    <button
                        disabled={page >= totalCount.length}
                        onClick={() =>
                            page <= totalCount.length ? setPage(page + 1) : null
                        }
                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 "
                    >
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    )
}
