import Select from 'react-select'

//
import { IFormSelectProps, IOption } from '../types'
import FormLabel from './FormLabel'

/**
 *
 */
export default function FormSelect({
    name,
    label,
    setValue,
    clearErrors,
    className,
    options,
    errors,
    disabled,
    defaultValue,
    isMulti,
    closeMenuOnSelect = true,
}: IFormSelectProps) {

    const hasError = Boolean(errors && errors[name])

    const handleSelectChange = (newValue: any) => {
        if (!newValue) return
        clearErrors(name)
        if (isMulti) {
            let value: number[] = newValue.map((value: IOption) => value.value);
            setValue(name, value)
        }
        else {
            setValue(name, newValue.value)
        }
    }

    return (
        <div className={`${className ?? ''} min-w-[238px]`}>
            {label && (
                <FormLabel
                    name={name}
                    label={label}
                />
            )}

            <Select
                options={options}
                onChange={handleSelectChange}
                isDisabled={disabled}
                defaultValue={defaultValue}
                closeMenuOnSelect={closeMenuOnSelect}
                isMulti={isMulti}
            />

            {hasError && (
                <div className="text-red-600 mt-1 text-sm">
                    {errors[name].message}
                </div>
            )}
        </div>
    )
}
