import { createSlice } from '@reduxjs/toolkit'

import { AppDispatch } from './store'
import { IExamState, ISetAnswers } from '../types'
import { TIMELIMIT } from '../constants'

const initialState: IExamState = {
    questions: [],
    currentQuestion: 0,
    result: {},
    answers: [],
    selectedOptions: [],
    remainingTime: TIMELIMIT * 60
}

const examSlice = createSlice({
    name: 'exam',
    initialState: initialState,
    reducers: {
        setQuestions: (state, action) => {
            state.questions = action.payload
        },
        setNextQuestion: (state) => {
            state.currentQuestion += 1;
        },
        setSelectedAnswer: (state, action) => {
            state.selectedOptions = action.payload
        },
        setPreviousQuestion: (state) => {
            state.currentQuestion -= 1
        },
        setQuestionIndex: (state, action) => {
            state.currentQuestion = action.payload
        },
        setAnswers: (state, action) => {
            const newAnswers = {
                ...state.answers,
                [action.payload.questionIndex]: action.payload.answers,
            }
            state.answers = newAnswers
        },
        setSelectedAnswerByQuestion: (state, action) => {
            state.selectedOptions = state.answers[action.payload] ?? []
        },
        setResult: (state, action) => {
            state.result = action.payload
        },
        setRemainingTime: (state, action) => {
            state.remainingTime = action.payload;
        },
        clearExamState: (state) => ({
            ...initialState
        })
    },
})

export const {
    setQuestions,
    setNextQuestion,
    setSelectedAnswer,
    setPreviousQuestion,
    setAnswers,
    setQuestionIndex,
    setSelectedAnswerByQuestion,
    setResult,
    clearExamState,
    setRemainingTime
} = examSlice.actions

export default examSlice.reducer

export const handleSetQuestions = (data: any) => (dispatch: AppDispatch) => {
    dispatch(setQuestions(data))
}

export const handleSetAnswers =
    (data: ISetAnswers) => (dispatch: AppDispatch) => {
        dispatch(setAnswers(data))
    }

export const handleSetSelectedOptions =
    (data: number[]) => (dispatch: AppDispatch) => {
        dispatch(setSelectedAnswer(data))
    }
export const handleGetSelectedAnswer =
    (questionIndex: number) => (dispatch: AppDispatch) => {
        dispatch(setSelectedAnswerByQuestion(questionIndex))
    }

export const handleResetSelectedAnswers = () => (dispatch: AppDispatch) => {
    dispatch(setSelectedAnswer([]))
}

export const handleNextQuestion = () => (dispatch: AppDispatch) => {
    dispatch(setNextQuestion())
}

export const handlePreviousQuestion = () => (dispatch: AppDispatch) => {
    dispatch(setPreviousQuestion())
}
export const handleSetQuestionIndex =
    (questionIndex: number) => (dispatch: AppDispatch) => {
        dispatch(setQuestionIndex(questionIndex))
    }
export const handleSetResult = (result: any) => (dispatch: AppDispatch) => {
    dispatch(setResult(result))
}
export const handleClearExamState = () => (dispatch: AppDispatch) => {
    dispatch(clearExamState())
}

export const handleSetRemainingTime = (time: number) => (dispatch: AppDispatch) => {
    dispatch(setRemainingTime(time))
}