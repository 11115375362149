import Image from '../elements/Image'
import Logo from '../assets/logo.png'
import Button from './Button'
import { FaSignOutAlt } from 'react-icons/fa'
import { useAppDispatch, useAppSelector } from '../_redux/redux'
import { logOutUser } from '../_redux/authSlice'
import { handleClearExamState } from '../_redux/exam'
import { handleResetUser } from '../_redux/userSlice'
import { Link } from 'react-router-dom'
import { defaultRouteForUser } from '../constants'
import { handleClearExamStudentInfo } from '../_redux/socket'

function Header() {
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.user.user);

    const handleLogout = () => {
        dispatch(logOutUser());
        dispatch(handleClearExamState());
        dispatch(handleClearExamStudentInfo());
        dispatch(handleResetUser());
    }
    return (
        <div className="bg-white">
            <div className="container mx-auto h-[70px] px-4 py-3 flex items-center justify-between">
                <Link to={user.role ? defaultRouteForUser[user.role] : "#"} className='cursor-pointer h-full'>
                    <Image src={Logo} alt="geeta-aviation" className="h-full" />
                </Link>



                <div className="flex items-center gap-6">
                    <div className=" text-gray-800">
                        <h4 className="text-xl m-0 font-bold">{user.first_name} {user.last_name}</h4>
                        <p className="m-0 text-sm font-medium capitalize">{user.role}</p>
                    </div>

                    <Button
                        className="flex justify-center"
                        onClick={handleLogout}
                        icon={<FaSignOutAlt />}
                    />
                </div>
            </div>
        </div>
    )
}

export default Header
