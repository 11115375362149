import { useState } from 'react'

//
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

//
import { IPasswordProps } from '../types'

/**
 *
 */
export default function Password({
    name,
    isRequired,
    register,
    disabled,
}: IPasswordProps) {
    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = () => setShowPassword((prev) => !prev)
    return (
        <div className="relative">
            <input
                id={name}
                name={name}
                type={showPassword ? 'text' : 'password'}
                required={isRequired}
                disabled={disabled}
                className="ga_input"
                {...(register ? register(name) : {})}
                autoComplete="new-password"
            />

            <span
                className="absolute top-2.5 right-3 cursor-pointer"
                onClick={toggleShowPassword}
            >
                {showPassword ? (
                    <AiFillEyeInvisible
                        className="text-gray-600"
                        fontSize="18"
                    />
                ) : (
                    <AiFillEye className="text-gray-600" fontSize="18" />
                )}
            </span>
        </div>
    )
}
