import { useForm } from 'react-hook-form'
import FormInput from '../../components/FormInput'

import Button from '../../components/Button'

import { IExamScheduleProps, IOption, IScheduleProps } from '../../types'
import {
    useMutationHook,
    useQueryHook,
} from '../../hooks/react-query/useQueryHook'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { examScheduleSchema } from '../../schemaValidations'
import { useEffect, useState } from 'react'
import FormSelect from '../../components/FormSelect'
import dayjs from 'dayjs'

export default function ExamScheduleForm({
    schedule,
    hideForm,
}: IExamScheduleProps) {
    const queryClient = useQueryClient()
    const [organizations, setOrganizations] = useState()

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
        clearErrors,
    } = useForm({
        defaultValues: {},
        resolver: yupResolver(examScheduleSchema),
    })

    useEffect(() => {
        if (!schedule) return
        setValue('organization', schedule.organization)
        setValue('start_date', dayjs(schedule.start_time).format('YYYY-MM-DD'))
        setValue('end_time', dayjs(schedule.end_time).format('HH:mm'))
        setValue('start_time', dayjs(schedule.start_time).format('HH:mm'))
    }, [schedule, setValue])

    useQueryHook({
        queryName: 'organizations',
        queryRoute: `/organizations/`,

        options: {
            onSuccess: (response: any) => {
                setOrganizations(
                    response.data.results.map((org: any) => ({
                        label: org.name,
                        value: org.id,
                    }))
                )
            },
        },
    })

    const { mutate, isLoading: isCreateCategoryLoading } = useMutationHook({
        queryRoute: '/schedules/',
        options: {
            onSuccess: () => {
                queryClient.invalidateQueries(['schedules'])

                hideForm()
                reset()
            },
            onError: (err) => {
                toast.error(err.detail)
            },
        },
    })

    const { mutate: updateExamSchedule, isLoading: isUpdateCategoryLoading } =
        useMutationHook({
            queryRoute: `/schedules/${schedule?.id}/`,
            method: 'patch',
            options: {
                onSuccess: () => {
                    queryClient.invalidateQueries(['schedules'])

                    hideForm()
                    reset()
                },
                onError: (err) => {
                    toast.error(err.detail)
                },
            },
        })

    const isLoading = isCreateCategoryLoading || isUpdateCategoryLoading

    const onSubmit = (data: IScheduleProps) => {
        const [startHour, startMinute]: string[] = data.start_time.split(':')
        const startDate = new Date(
            new Date(new Date(data.start_date).setHours(+startHour)).setMinutes(
                +startMinute
            )
        )
        // startDate.set('hour', +startHour).set('minute', +startMinute)
        const [endHour, endMinute]: string[] = data.end_time.split(':')
        const endDate = new Date(
            new Date(new Date(data.start_date).setHours(+endHour)).setMinutes(
                +endMinute
            )
        )
        //

        const newData = {
            ...data,
            start_time: startDate,
            end_time: endDate,
        }

        if (schedule) {
            updateExamSchedule(newData)
        } else {
            mutate(newData)
        }
    }

    return (
        <div className="py-5 px-5 bg-gray-100 rounded mb-8">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <FormSelect
                        name="organization"
                        label="Organization"
                        setValue={setValue}
                        errors={errors}
                        disabled={isLoading}
                        clearErrors={clearErrors}
                        options={organizations}
                        defaultValue={
                            schedule
                                ? {
                                    value: schedule.organization || '',
                                    label: schedule.organization_name,
                                } as IOption
                                : undefined
                        }
                    />
                </div>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <FormInput
                        type="date"
                        name="start_date"
                        label="Exam Date"
                        register={register}
                        errors={errors}
                        disabled={isLoading}
                    />
                    <FormInput
                        type="time"
                        name="start_time"
                        label="Start Time"
                        register={register}
                        errors={errors}
                        disabled={isLoading}
                    />

                    <FormInput
                        type="time"
                        name="end_time"
                        label="End Time"
                        register={register}
                        errors={errors}
                        disabled={isLoading}
                    />
                </div>

                <div className="flex justify-end gap-4">
                    <Button
                        className="mt-5"
                        label="Cancel"
                        varient="outline-danger"
                        disabled={isLoading}
                        isLoading={isLoading}
                        type="button"
                        onClick={hideForm}
                    />
                    <Button
                        className="mt-5"
                        label="Submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                </div>
            </form>
        </div>
    )
}
