import { Link } from 'react-router-dom'

//
import { ICategoryCardProps } from '../types'

/**
 * 
 */
export default function CategoryCard({ category }: ICategoryCardProps) {
    return (
        <Link
            to={`${category.id}`}
            className={`p-6 border shadow hover:shadow-lg ${category.for_test ? "bg-red-100" : "bg-blue-100"} text-gray-800`}
        >
            <h4 className="text-2xl font-bold">{category.title}</h4>

            <span className="text-3xl font-bold text-right block">
                {category.count}
            </span>
        </Link>
    )
}
