import { ILabelValueTextProps } from '../types'

export default function LabelValueText({ label, value }: ILabelValueTextProps) {
    return (
        <div className="mb-4 text-gray-800">
            <span className="font-bold text-xl"> {label}</span>:{' '}
            <span className="font-medium text-lg">{value}</span>
        </div>
    )
}
