import dayjs from "dayjs";

//
import Image from "../elements/Image";
import LabelValueText from "../elements/LabelValueText";

//
import Logo from '../assets/logo.png';

//
import { IResultCardProps } from "../types";
import { getLicenceFromUsername, getPercentage } from "../helpers/methods";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../_redux/redux";
import { handleSetResult } from "../_redux/exam";

/**
 * 
 */
export default function ResultCard({
    printRef,
    user,
}: IResultCardProps) {
    const dispatch = useAppDispatch();
    const result = useAppSelector(state => state.exam.result);
    useEffect(() => {
        if (user?.total_marks) {
            dispatch(handleSetResult({
                obtained: user.obtained_marks,
                total: user.total_marks
            }))
        }
    }, [dispatch, user])

    const percentage = getPercentage(result?.obtained, result?.total)

    return (
        <div className="flex items-center flex-col p-4" ref={printRef}>
            <Image
                src={Logo}
                alt="geeta aviation"
                className="!max-w-[325px]"
            />

            <div className="mt-12 text-gray-800">
                <h1 className="font-bold text-4xl text-center uppercase">
                    Airline Pilot Assessment
                </h1>
                <h2 className="text-center font-bold text-2xl uppercase my-5">
                    {user.organization_name}
                </h2>
            </div>

            <hr className="w-4/5 border-2" />
            {user && (
                <div className="w-full mt-6 lg:w-[600px]">
                    <div>
                        <LabelValueText
                            label="Name"
                            value={`${user.first_name} ${user.last_name}`}
                        />
                        <LabelValueText
                            label="License number"
                            value={user.licence_number 
                                ? user.licence_number : 
                                getLicenceFromUsername(user.username || user.user?.username ||  "")}
                        />
                        <LabelValueText
                            label="Date"
                            value={dayjs(user.end_time).format('YYYY-MM-DD')}
                        />
                    </div>

                    <p className="py-3 text-center  text-gray-700">
                        <div className="text-2xl font-medium">Thank You for your Submission</div>
                        <p className="text-md mb-4">You Have Completed Your Exam</p>
                        <div className="text-2xl">You Have Scored
                            <span className="ml-2 font-extrabold">
                                {percentage}
                            </span>
                        </div>

                        <div className="h-[150px] w-[200px] flex items-end justify-end">

                            <div className="flex flex-col w-full ">
                                <hr className="w-full mb-3 border-gray-800" />
                                Invigilator
                            </div>
                        </div>
                    </p>
                </div>
            )
            }
        </div >
    )
}