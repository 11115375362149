import { useTimer } from 'react-timer-hook'
import { DANGER_TIME_IN_SECOND } from '../constants';
import { useAppSelector } from '../_redux/redux';

export default function Timer({ handleSubmitAnswers }: { handleSubmitAnswers: () => void }) {
    const remainingTime = useAppSelector(state => state.exam.remainingTime);
    const expiryTimestamp = new Date(new Date().getTime() + remainingTime * 1000);

    const stopAndSubmit = () => {
        handleSubmitAnswers();
    }

    const { totalSeconds, seconds, minutes, hours } = useTimer({
        expiryTimestamp,
        autoStart: true,
        onExpire: () => {
            stopAndSubmit()
        },
    })


    return (
        <div
            className={`text-3xl font-orbitron font-bold tracking-widest ${totalSeconds < DANGER_TIME_IN_SECOND
                ? 'text-red-600 font-extrabold'
                : 'text-gray-700'
                }`}
        >
            <span>{('0' + hours).slice(-2)}</span>:
            <span>{('0' + minutes).slice(-2)}</span>:
            <span>{('0' + seconds).slice(-2)}</span>
        </div>
    )
}
