export const establishPublicWebSocketConnection = (token: string) => {
	const ENDPOINT = process.env.REACT_APP_BACKEND_WEBSOCKET_API;

	try {
		const ws = new WebSocket(`${ENDPOINT}/socket-pool/`);
		const apiCall = {
			access: token,
		};

		ws.onopen = () => {
			ws.send(JSON.stringify(apiCall));
			console.log('Connection to public web socket Opened successfully!');
		};

		return ws;
	} catch (error) {
		console.error('Error in Pubic Socket: Socket is closed', error);
		return null;
	}
};