import { jwtDecode } from 'jwt-decode';
import { createContext, useEffect, useRef } from 'react';

//
import {
	establishPublicWebSocketConnection,
} from './websocketConnection';
import {
	ApplicantWebSocketEventHandler,
	InvigilatorWebSocketEventHandler,
} from './WebSocketEventHandler';
import { useAppDispatch, useAppSelector } from '../_redux/redux';

//
import { IWebSocketProviderProps } from '../types';
import { LOCAL_ACCESS_TOKEN_NAME } from '../constants';

//
const WebSocketContext = createContext(null);
export { WebSocketContext };

/**
 *
 */
const WebSocketProvider = ({ children }: IWebSocketProviderProps) => {
	let wsPublic: any = useRef(null);
	const dispatch = useAppDispatch();
	const reduxRole = useAppSelector(state => state.user.user.role);

	const token = useAppSelector((state) => state.auth.token);
	const accessToken = token || localStorage.getItem(LOCAL_ACCESS_TOKEN_NAME)

	useEffect(() => {
		if (wsPublic && accessToken) {
			const { role }: any = jwtDecode(accessToken);
			if (reduxRole !== role) {
				wsPublic.current?.close();
			}
			wsPublic.current = establishPublicWebSocketConnection(accessToken);

			if (wsPublic?.current) {
				if (role === "invigilator") {
					wsPublic.current.onmessage = (event: any) =>
						InvigilatorWebSocketEventHandler(event, dispatch);
				}
				else if (role === "applicant") {
					wsPublic.current.onmessage = (event: any) =>
						ApplicantWebSocketEventHandler(event, dispatch, accessToken);
				}

				wsPublic.current.onclose = (e: any) => {
					console.log(
						`Public Socket is closed. Reconnect will be attempted in ${Math.min(
							10000 / 1000
						)} second.`,
						e.reason
					);
				};

				wsPublic.current.onerror = (err: any) => {
					console.error(
						'Public Socket encountered error: ',
						err.message,
						'Closing socket'
					);
					wsPublic.current.close();
				};
			}
		}
		else if (wsPublic) {
			wsPublic.current?.close();
		}
	}, [accessToken, dispatch, reduxRole]);

	return (
		<WebSocketContext.Provider value={wsPublic} >
			{children}
		</WebSocketContext.Provider>
	);
};

export default WebSocketProvider;
