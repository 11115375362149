import React from 'react'
import { IExamSidebarButtonProps } from '../types'

export default function ExamSidebarButton({
    icon,
    label,
    onClick,
    className,
    disabled,
}: IExamSidebarButtonProps) {
    return (
        <button
            className={`cursor-pointer border-2 min-h-[80px] w-full flex justify-center items-center p-6 ${className} disabled:opacity-80 disabled:bg-gray-200`}
            onClick={onClick}
            disabled={disabled}
        >
            {icon && <div className="mr-4 ">{icon}</div>}
            <span className="text-2xl font-medium uppercase text-center">
                {label}
            </span>
        </button>
    )
}
