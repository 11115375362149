import { IFormLabel } from "../types";

/**
 *
 */
export default function FormLabel({
    label,
    name,
}: IFormLabel) {
    return (
        <label
            htmlFor={name}
            className="block uppercase text-sm font-medium text-gray-700"
        >
            {label}
        </label>

    )
}
