import { useFieldArray } from 'react-hook-form'
import FormInput from '../../components/FormInput'
import Button from '../../components/Button'
import { MdDeleteOutline } from 'react-icons/md'
import { IOptionFormProps } from '../../types'
import { IoMdAdd } from 'react-icons/io'

const CategoryQuestionOptionForm = ({
    control,
    index,
    register,
    errors,
    isLoading,
    getValues,
    clearErrors
}: IOptionFormProps) => {
    const {
        fields: optionFields,
        append: optionAppend,
        remove: removeOptions,
        replace: replaceOptions,
    } = useFieldArray({
        control,
        name: `questions.${index}.options`,
    });

    const handleOnClick = (event: any, idx: number) => {
        let tempOptionFields: any = [...optionFields];
        if (event.target.checked) {
            tempOptionFields = tempOptionFields.map((field: any, i: number) => ({
                ...field,
                option: getValues(`questions.${index}.options.${i}.option`),
                is_correct: false
            }));

            tempOptionFields[idx].is_correct = true;
            clearErrors(`questions.${index}.question`)
        }
        else {
            tempOptionFields[idx].is_correct = false;
        }


        replaceOptions(tempOptionFields);
    }

    return (
        <div className="mt-2 mr-2">
            {optionFields?.map((field, idx) => (
                <div className="pt-2 px-2 mb-2 bg-white" key={field.id}>
                    <div className="flex items-start mb-1">
                        <FormInput
                            name={`questions.${index}.options.${idx}.option`}
                            label={`Option ${idx + 1}`}
                            register={register}
                            errors={{
                                [`questions.${index}.options.${idx}.option`]:
                                    errors?.questions
                                        ? errors?.questions[index]?.options ? errors?.questions[index]?.options[
                                            index
                                        ]?.option : {}
                                        : {},
                            }}
                            disabled={isLoading}
                            className="flex-1"
                        />

                        <Button
                            icon={<MdDeleteOutline />}
                            className="mt-5 ml-2"
                            varient="danger"
                            disabled={optionFields.length === 1 || isLoading}
                            isLoading={isLoading}
                            onClick={() => removeOptions(idx)}
                        />
                    </div>
                    <div className="mb-2">
                        <input
                            type="checkbox"
                            id={`questions.${index}.options.${idx}.is_correct`}
                            name={`questions.${index}.options.${idx}.is_correct`}
                            {...(register
                                ? register(
                                    `questions.${index}.options.${idx}.is_correct`
                                )
                                : {})}
                            onChange={(event) => handleOnClick(event, idx)}
                        />
                        <label
                            htmlFor={`questions.${index}.options.${idx}.is_correct`}
                        >
                            &nbsp; This is correct answer
                        </label>
                    </div>
                    <hr />
                </div>
            ))}
            <div className="flex justify-end">
                <Button
                    icon={<IoMdAdd />}
                    type="button"
                    className="mt-5"
                    label="Add Option"
                    disabled={optionFields.length >= 4 || isLoading}
                    isLoading={isLoading}
                    onClick={() => optionAppend({ option: '' })}
                />
            </div>
        </div>
    )
}

export default CategoryQuestionOptionForm
