import { toast } from 'react-toastify'
import { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

//
import Button from '../../components/Button'
import FormInput from '../../components/FormInput'
import BackButton from '../../components/BackButton'

import { ICategory, IQuestionsMain } from '../../types'
import {
    useMutationHook,
    useQueryHook,
} from '../../hooks/react-query/useQueryHook'
import { questionSchema } from '../../schemaValidations'
import AdminLayout from '../../layout/AdminLayout'
import { MdDeleteOutline } from 'react-icons/md'
import CategoryQuestionOptionForm from './CategoryQuestionOptionForm'
import { IoMdAdd } from 'react-icons/io'
import { useAppSelector } from '../../_redux/redux'

/**
 * 
 */
const CategoryQuestionForm = () => {
    const user = useAppSelector(state => state.user.user);
    const { category_id, question_id } = useParams()
    const [category, setCategory] = useState<ICategory>()
    const [question, setQuestion] = useState<IQuestionsMain>({
        questions: [
            {
                question: '',
                options: [
                    { option: '' },
                    { option: '' },
                    { option: '' },
                    { option: '' },
                ],
            },
        ],
    })

    const navigate = useNavigate();

    const role = user?.role === "superuser" ? "super-admin" : "admin";

    const handleFetchCategorySuccess = (response: any) => {
        setCategory(response.data)
    }

    const handleFetchQuestionSuccess = (response: any) => {
        setQuestion({
            questions: [
                {
                    question: response.data?.question,
                    options: response.data?.option_set?.map((option: any) => ({
                        option: option.option,
                        is_correct: option.is_correct,
                    })),
                },
            ],
        })
    }

    useQueryHook({
        queryName: `category-${category_id}`,
        queryRoute: `/categories/${category_id}/`,
        options: {
            onSuccess: handleFetchCategorySuccess,
        },
    })

    useQueryHook({
        queryName: `questions-${question_id}`,
        queryRoute: `/questions/${question_id}/`,
        options: {
            onSuccess: handleFetchQuestionSuccess,
            enabled: Boolean(question_id),
        },
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
        setError,
        clearErrors,
        getValues,
    } = useForm<IQuestionsMain>({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            return question
        }, [question]),
        resolver: yupResolver<IQuestionsMain>(questionSchema),
    })

    const {
        fields: questionFields,
        append: questionAppend,
        remove: questionRemove,
    } = useFieldArray({
        control,
        name: 'questions',
    });

    const { mutate, isLoading: isCreatingQuestion } = useMutationHook({
        queryRoute: '/create-questions/',
        options: {
            onSuccess: () => {
                reset()
                navigate(`/${role}/category/${category_id}`)
            },
            onError: (err) => {
                toast.error(err.detail)
            },
        },
    })

    const { mutate: updateQuestion, isLoading: isUpdatingQuestion } =
        useMutationHook({
            queryRoute: `/questions/${question_id}/`,
            method: 'patch',
            options: {
                onSuccess: () => {
                    reset()
                    navigate(`/${role}/category/${category_id}`)
                },
                onError: (err) => {
                    toast.error(err.detail)
                },
            },
        })

    const isLoading = isCreatingQuestion || isUpdatingQuestion

    useEffect(() => {
        if (question?.questions?.length) {
            setValue(`questions.0.question`, question?.questions[0].question)
            if (question.questions?.[0].options?.length) {
                question.questions?.[0].options?.forEach((option, index) => {
                    // setValue(
                    //     `questions.0.options.${index}.option`,
                    //     option.option
                    // )
                    // setValue(
                    //     `questions.0.options.${index}.is_correct`,
                    //     Boolean(option.is_correct)
                    // )
                    setValue(`questions.0.options.${index}`, {
                        option: option.option,
                        is_correct: option.is_correct,
                    })
                })
            }
        }
    }, [question, setValue]);

    const isvalidQuestions = (data: IQuestionsMain) => {
        let isValid = true;
        data.questions.forEach((question, idx) => {
            let questionHasCorrectAnswer = false;
            question.options.forEach(option => {
                if (option.is_correct) questionHasCorrectAnswer = true;
            })
            if (!questionHasCorrectAnswer) {
                setError(`questions.${idx}.question`, {
                    type: 'custom',
                    message: "Question must have one correct answer."
                })
                isValid = false;
            }
        })

        return isValid;
    }

    const onSubmit = (data: IQuestionsMain) => {
        if (!isvalidQuestions(data)) return;
        const requestData = (data.questions || []).map((question) => ({
            ...question,
            category: category_id,
            question_type:
                question.options.filter((option) => option.is_correct).length >
                    1
                    ? 'multiple'
                    : 'single',
        }))
        if (question_id) {
            if (requestData.length) updateQuestion(requestData[0])
        } else mutate(requestData)
    }

    const handleOnAddQuestionClick = () => {
        questionAppend([
            {
                category: category_id,
                question: '',
                options: [
                    { option: '' },
                    { option: '' },
                    { option: '' },
                    { option: '' },
                ],
            },
        ])
    }

    return (
        <AdminLayout>
            <div>
                <div className='flex items-center group w-fit'>
                    <BackButton />
                    <div className='text-gray-800'>
                        <h3 className="text-xl font-bold">
                            {question_id ? 'Edit' : 'Add'} Question
                        </h3>
                        <h3 className="text-3xl font-bold"> {category?.title}</h3>
                    </div>
                </div>

            </div>
            <div className="py-10">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {questionFields?.map((question, index) => (
                        <div>
                            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-6 p-4 mb-4  bg-gray-100">
                                <div>
                                    <FormInput
                                        className="mb-4"
                                        type="textarea"
                                        name={`questions.${index}.question`}
                                        label={`Question ${index + 1}`}
                                        register={register}
                                        errors={{
                                            [`questions.${index}.question`]:
                                                errors?.questions
                                                    ? errors?.questions[index]
                                                        ?.question
                                                    : {},
                                        }}
                                        disabled={isLoading}
                                        rowsSpan={5}
                                    />

                                    <div className="flex justify-end">
                                        <Button
                                            icon={<MdDeleteOutline />}
                                            label="Delete Question"
                                            varient="danger"
                                            disabled={
                                                questionFields.length <= 1 ||
                                                isLoading
                                            }
                                            isLoading={isLoading}
                                            onClick={() =>
                                                questionRemove(index)
                                            }
                                        />
                                    </div>
                                </div>

                                <CategoryQuestionOptionForm
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    isLoading={isLoading}
                                    index={index}
                                    getValues={getValues}
                                    clearErrors={clearErrors}
                                />
                            </div>
                        </div>
                    ))}
                    {!question_id && (
                        <div className="flex justify-end">
                            <Button
                                icon={<IoMdAdd />}
                                type="button"
                                className="mt-5"
                                label="Add Question"
                                disabled={isLoading}
                                isLoading={isLoading}
                                onClick={handleOnAddQuestionClick}
                            />
                        </div>
                    )}

                    <div className="flex justify-end">
                        <Button
                            className="mt-5"
                            label="Submit"
                            disabled={isLoading}
                            isLoading={isLoading}
                        />
                    </div>
                </form>
            </div>
        </AdminLayout>
    )
}

export default CategoryQuestionForm
