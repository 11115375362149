//
import { useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../_redux/redux'

//
import Button from '../../components/Button'

//
import { logOutUser } from '../../_redux/authSlice'
import { handleResetUser } from '../../_redux/userSlice'
import { handleClearExamState } from '../../_redux/exam'
import { handleClearExamStudentInfo } from '../../_redux/socket'
import ResultCard from '../../components/ResultCard'


/**
 * 
 */
export default function ResultPage() {
    const printRef = useRef(null);
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user.user);

    useEffect(() => {
        // prevent user from going back
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
    }, []);

    const handleLogout = () => {
        dispatch(handleClearExamState());
        dispatch(handleResetUser());
        dispatch(handleClearExamStudentInfo());
        dispatch(logOutUser());
    }


    return (
        <div className="min-h-screen flex justify-center items-start bg-light-200">
            <div className="p-10 mt-20 bg-light-100">
                <ResultCard
                    printRef={printRef}
                    user={user}
                />

                <div className="mt-10 flex items-center justify-center">
                    <Button label="Finish" className='w-full text-center justify-center' onClick={handleLogout} />
                </div>
            </div>
        </div>
    )
}
