import { IExamProgressItemProps } from "../types";
import { handleSetAnswers, handleSetQuestionIndex } from "../_redux/exam";
import { useAppDispatch, useAppSelector } from "../_redux/redux";

export default function ExamProgressItem({ page, active, completed }: IExamProgressItemProps) {
    const dispatch = useAppDispatch();

    const selectedOptions = useAppSelector(
        (state) => state.exam.selectedOptions
    )
    const currentQuestion = useAppSelector(
        (state) => state.exam.currentQuestion
    )
    const handleGoToPage = () => {
        dispatch(
            handleSetAnswers({
                answers: selectedOptions,
                questionIndex: currentQuestion,
            })
        )
        dispatch(handleSetQuestionIndex(page - 1))
    };

    return (
        <div className={`text-sm min-w-[28px] min-h-[28px] flex justify-center items-center rounded-full cursor-pointer border-4
         ${active ? " border-primary-200 !text-primary-200 bg-white font-semibold" : ""}
         ${completed ? " border-primary-200 bg-primary-200 text-white" : ""}
        bg-gray-200`} onClick={handleGoToPage}>
            {page}
        </div>
    )
}