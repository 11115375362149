import { useState } from "react";
import { useQueryHook } from "../../hooks/react-query/useQueryHook";
import { IQuestion, IQuestionSearchProps } from "../../types";
import QuestionItem from "./QuestionItem";
import NoContentSection from "../../components/NoContentSection";
import Pagination from "../../components/Pagination";

export default function QuestionSearch({ questionSearch }: IQuestionSearchProps) {
    const [questions, setQuestions] = useState<IQuestion[] | []>([])
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)

    const handleFetchCategoryQuestionSuccess = (response: any) => {
        setQuestions(response.data.results)
        setCount(response.data.count)
    }

    const handleSetPage = (page: number) => {
        setPage(page)
    }

    useQueryHook({
        queryName: `questions`,
        queryRoute: `/questions/`,
        params: [
            ['offset', (page - 1) * 10],
            ['question', questionSearch],
            ['limit', 10],
        ],
        options: {
            onSuccess: handleFetchCategoryQuestionSuccess,
        },
    })

    return (
        <div className="w-full">
            <div className=" w-full rounded-2xl bg-white ">
                {questions.length > 0 ?
                    questions.map((question: IQuestion) => (
                        <QuestionItem
                            question={question}
                            id={question.category}
                            role={'super-admin'}
                        />
                    )) :
                    <NoContentSection title='Questions' />
                }
            </div>
            <Pagination
                total={count}
                perPage={10}
                setPage={handleSetPage}
                page={page}
            />
        </div>
    )
}