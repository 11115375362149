import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query'
import ReactFlagsSelect from 'react-flags-select'
import { yupResolver } from '@hookform/resolvers/yup';

//
import { useMutationHook, useQueryHook } from '../../hooks/react-query/useQueryHook'
import { errorMessageHandler } from '../../helpers/methods';

//
import Header from '../../components/Header'
import Button from '../../components/Button'
import FormInput from '../../components/FormInput'
import FormSelect from '../../components/FormSelect'

//
import FormSection from '../../elements/FormSection'

//
import { IOrganization, IStudentRegister } from '../../types'
import { studentRegisterSchema } from '../../schemaValidations';
import { AIRCRAFT_TYPE, LICENSE_TYPE } from '../../constants'

//
import { useAppDispatch } from '../../_redux/redux'
import { handleSetCredentials } from '../../_redux/authSlice'

/**
 *
 */
export default function StudentRegister() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            licence_type: '',
            type: '',
            number: undefined,
            validity: '',
            country: '',
            organization: undefined,
        },
        resolver: yupResolver(studentRegisterSchema),
    })
    const [selectedCountry, setSelectedCountry] = useState('')

    const { mutate, isLoading } = useMutationHook({
        queryRoute: '/applicant/',
        options: {
            onSuccess: (response) => {
                dispatch(
                    handleSetCredentials({
                        access: response.data.access,
                        refresh: response.data.refresh,
                    })
                )
                queryClient.invalidateQueries(['me'])
                navigate('/')
            },
            onError: (err) => {
                errorMessageHandler(err);
            },
        },
    });

    const { data: organizations } = useQueryHook({
        queryName: "student-register-organziation",
        queryRoute: "/organizations/",
        options: {
            onSuccess: (response) => {
                if (response.data.results.length) {
                    setValue('organization', response.data.results[0].id)
                }
            }
        }
    });

    const airlines = (organizations?.data?.results ?? []).map((organization: IOrganization) => ({ label: organization.name, value: organization.id }));

    const onSubmit = (data: IStudentRegister) => {
        if (!data.country) {
            return setError('country', { type: 'custom', message: 'Country is required!' })
        }
        const licenceNumber = `${data.type}-${data.number}`
        const body = {
            ...data,
            licence_number: licenceNumber,
            username: `${data.organization}-${licenceNumber}`,
            password: Math.floor(Math.random() * 10000000),
        }
        delete body.number
        delete body.type
        mutate(body)
    }
    return (
        <div className="min-h-screen bg-light-200">
            <Header />

            <div className=" flex justify-center items-start p-10 mt-10 bg-light-100 mx-auto w-[90%] sm:w-[540px] md:w-[540px] lg:w-[760px] xl:w-[1200px]">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormSection label="STUDENT DETAILS">
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                            <FormInput
                                name="first_name"
                                label="First name"
                                register={register}
                                errors={errors}
                                disabled={isLoading}
                            />

                            <FormInput
                                name="last_name"
                                label="Last name"
                                register={register}
                                errors={errors}
                                disabled={isLoading}
                            />

                            <FormInput
                                name="date_of_birth"
                                label="Date of Birth"
                                type="date"
                                register={register}
                                errors={errors}
                                disabled={isLoading}
                            />

                            <FormInput
                                name="seat_plan"
                                label="Seat Plan"
                                type="text"
                                register={register}
                                errors={errors}
                                disabled={isLoading}
                            />
                        </div>
                    </FormSection>

                    <FormSection label="LICENSE DETAILS">
                        <div className="flex flex-col items-start gap-6">
                            <div className="flex flex-col sm:flex-row gap-6">
                                <FormSelect
                                    name="organization"
                                    label="Airlines"
                                    options={airlines}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                    errors={errors}
                                    disabled={isLoading}
                                />

                                <FormSelect
                                    name="licence_type"
                                    label="Aircraft Type"
                                    type="select"
                                    options={AIRCRAFT_TYPE}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                    errors={errors}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="flex flex-col sm:flex-row gap-6">
                                <FormSelect
                                    name="type"
                                    label="Type"
                                    type="select"
                                    options={LICENSE_TYPE}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                    errors={errors}
                                    disabled={isLoading}
                                />
                                <FormInput
                                    name="number"
                                    label="Licence number"
                                    type="number"
                                    register={register}
                                    disabled={isLoading}
                                    errors={errors}
                                />
                            </div>

                            <div className="min-w-[237px]">
                                <label className="block uppercase text-sm font-medium text-gray-700">
                                    Country
                                </label>

                                <ReactFlagsSelect
                                    selected={selectedCountry}
                                    onSelect={(code) => {
                                        clearErrors('country');
                                        setSelectedCountry(code);
                                        setValue('country', code);
                                    }}
                                    showSelectedLabel={true}
                                    showSecondarySelectedLabel={true}
                                    selectedSize={16}
                                    showOptionLabel={true}
                                    showSecondaryOptionLabel={true}
                                    optionsSize={16}
                                    placeholder="Select a country"
                                    searchable={true}
                                    alignOptionsToRight={false}
                                    fullWidth={true}
                                    disabled={isLoading}
                                />

                                {Boolean(errors && errors['country']) && (
                                    <div className="text-red-600 mt-1 text-sm">
                                        {errors['country']?.message}
                                    </div>
                                )}
                            </div>

                            <FormInput
                                name="validity"
                                label="Validity"
                                type="date"
                                register={register}
                                errors={errors}
                                disabled={isLoading}
                            />
                        </div>
                    </FormSection>

                    <div className="flex justify-end">
                        <Button
                            className="mt-5"
                            label="Submit"
                            disabled={isLoading}
                            isLoading={isLoading}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
