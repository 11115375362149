import { persistReducer } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'
import localForage from 'localforage';

import authReducer from './authSlice'
import socketReducer from './socket'
import userReducer from './userSlice'
import examReducer from './exam'
// import { LOCAL_ACCESS_TOKEN_NAME } from '../constants';


const persistConfig = {
    key: "root",
    version: 1,
    storage: localForage,
};

const reducers = combineReducers({
    auth: authReducer,
    user: userReducer,
    exam: examReducer,
    socket: socketReducer
});

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
