import { toast } from 'react-toastify'

export function getLicenceFromUsername(username: string) {
    return username.split('-').slice(1, 3).join('-')
}

export function errorMessageHandler(error: { [field: string]: string[][] }) {
    if (error) {
        let errorMessages = Object.values(error)

        if (errorMessages.length > 0) {
            errorMessages[0].forEach((message) => toast.error(message))
        }
    }
}

export const truncate = (num: string | number, fixed = 2) => {
    if (num) {
        var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
        const number =
            num && num.toString().match(re) ? num.toString().match(re)?.[0] : 0
        return Number(number)
    } else {
        return 0
    }
}

export const getPercentage = (obtained: number, total: number) => {
    if (!total) return "Issue";
    
    const percentage = truncate(
        `${(obtained / total) * 100}`
    );
    return `${(isNaN(+percentage) ? "0" : percentage)}%`
}