import { useState } from 'react'

//
import { SubjectType } from '../../types'
import { useAppSelector } from '../../_redux/redux'
import { checkPermission } from '../../roles/check-roles'

//
import AdminLayout from '../../layout/AdminLayout'

//
import CategoryForm from './CategoryForm'
import CategoryList from './CategoryList'
import TabNav from '../../components/TabNav'
import Button from '../../components/Button'
import BackButton from '../../components/BackButton'

//
import { IoMdAdd } from 'react-icons/io'

/**
 * 
 */
export default function CategoryListing() {
    const [open, setOpen] = useState(false)
    const [activeTab, setActiveTab] = useState<SubjectType>('exam')

    const user = useAppSelector(state => state.user.user);

    const handleOpenCategoryForm = () => {
        setOpen((prev) => !prev)
    }

    const handleTabClick = (value: SubjectType) => setActiveTab(value);

    return (
        <AdminLayout>
            <div className="flex justify-between items-center mb-6">
                <div className='flex group'>
                    <BackButton />
                    <h2 className="text-3xl font-bold text-gray-800">
                          Subjects {activeTab === "test" && "For Internal "}
                    </h2>
                </div>

                <div className='bg-gray-100 p-2 gap-4 flex'>
                    <TabNav
                        label='Exam'
                        value='exam'
                        onClick={handleTabClick}
                        isActive={'exam' === activeTab}
                    />

                    <TabNav
                        label='Internal'
                        value='test'
                        onClick={handleTabClick}
                        isActive={'test' === activeTab}
                    />
                </div>

                <div>

                    {
                        checkPermission(user?.role, 'create:category') &&
                        <Button
                            icon={<IoMdAdd />}
                            label="Add Subject"
                            onClick={() => handleOpenCategoryForm()}
                        />
                    }
                </div>
            </div>

            {open && (
                <div>
                    <CategoryForm hideForm={handleOpenCategoryForm} />
                </div>
            )}

            <CategoryList
                type={activeTab}
            />

        </AdminLayout>
    )
}
