import { useRef } from 'react'
import { IImageProps } from '../types'

const DEFAULT_IMAGE_PLACEHOLDER = '/assets/placeholder.jpg';
export default function Image({ src, alt, className }: IImageProps) {
    const imgSrc = src || DEFAULT_IMAGE_PLACEHOLDER;
    const imgRef = useRef<HTMLImageElement>(null)

    const handleError = () => {
        if (!imgRef.current) return

        imgRef.current.src = DEFAULT_IMAGE_PLACEHOLDER;
    }

    return (
        <img
            className={`max-w-full h-auto ${className ?? ''}`}
            ref={imgRef}
            src={imgSrc}
            alt={alt}
            onError={handleError}
        />
    )
}
