import { useAppDispatch, useAppSelector } from '../_redux/redux'
import { LOCAL_ACCESS_TOKEN_NAME } from '../constants'
import { setCredentials } from '../_redux/authSlice'
import { jwtDecode } from 'jwt-decode'

export default function useAuth() {
    const dispatch = useAppDispatch()
    const reduxToken = useAppSelector((state) => state.auth.token)
    let token: string | null = reduxToken
    if (!reduxToken) {
        const localToken = localStorage.getItem(LOCAL_ACCESS_TOKEN_NAME)
        if (localToken) {
            token = localToken
            dispatch(setCredentials(token))
        }
    }

    if (token) {
        const { role }: any = jwtDecode(token)
        return { token, role: role }
    }

    return { token: null }
}
