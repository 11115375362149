import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";

//
import SuperAdminLayout from "../../layout/SuperAdminLayout";

//
import Button from "../../components/Button";
import FormInput from "../../components/FormInput";
import FormLabel from "../../components/FormLabel";
import FormImage from "../../components/FormImage";
import BackButton from "../../components/BackButton";
import OrganizationCategoriesListing from "../../components/OrganizationCategoriesListing";

//
import { IOrganization, ISelectedCategory } from "../../types";
import { useMutationHook, useQueryHook } from "../../hooks/react-query/useQueryHook";

//
import { FaTrash } from "react-icons/fa";
import { VscLoading } from "react-icons/vsc";
import { BsThreeDotsVertical } from 'react-icons/bs';

/**
 * 
 */
export default function OrganizationForm() {
    const { form } = useParams();
    const navigate = useNavigate();
    const [organization, setOrganization] = useState<IOrganization>();
    const [organizationCategoriesPreferences, setOrganizationCategoriesPreferences] = useState<ISelectedCategory[]>([]);

    const [organizationLogo, setOrganizationLogo] = useState()

    const isCreate = form && form.includes('create');

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name: "",
            is_test_organization: false
        }
    });

    const handleCreateOrganizationSuccess = (response: any) => {
        setOrganization(response.data);
        toast.success('Organization created successfully!')
    }

    const handleUpdateOrganizationSuccess = (response: any) => {
        setOrganization(response.data);
        toast.success('Organization updated successfully!')
    }

    const handleDeleteOrganizationSuccess = (response: any) => {
        navigate('/super-admin/organizations');
        toast.success('Organization deleted successfully!')
    }



    const { mutate: createOrganization, isLoading: isCreateLoading } = useMutationHook({
        queryRoute: '/organizations/',
        axiosOptions: {
            multipart: true
        },
        options: {
            onSuccess: handleCreateOrganizationSuccess
        }
    })
    const { mutate: updateOrganization, isLoading: isUpdateLoading } = useMutationHook({
        queryRoute: `/organizations/${organization?.id}/`,
        method: 'patch',
        axiosOptions: {
            multipart: true
        },
        options: {
            onSuccess: handleUpdateOrganizationSuccess
        }
    })

    const { mutate: deleteOrganization, isLoading: isDeleteLoading } = useMutationHook({
        queryRoute: `/organizations/${organization?.id}/`,
        method: 'delete',
        options: {
            onSuccess: handleDeleteOrganizationSuccess
        }
    })

    const isLoading = isCreateLoading || isUpdateLoading;

    const onSubmit = (data: { name: string, is_test_organization : boolean }) => {
        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('is_test_organization', `${data.is_test_organization}`);

        if (organizationLogo) {
            formData.append('icon', organizationLogo);
        }

        if (isCreate) {
            createOrganization(formData);
        }
        else {
            updateOrganization(formData);
        }
    }


    useQueryHook({
        queryName: "organization",
        queryRoute: `/organizations/${form}/`,
        options: {
            enabled: isCreate ? false : true,
            onSuccess: (response: any) => {
                const organization: IOrganization = response.data;
                setOrganization(organization);
                setValue("name", organization.name);
                setValue("is_test_organization", organization.is_test_organization ?? false);
            }
        }
    })

    useQueryHook({
        queryName: "organization-preferred-categories",
        queryRoute: `/organizations/${form}/preferred-categories/`,
        options: {
            enabled: isCreate ? false : true,
            onSuccess: (response: any) => {
                const preferences: ISelectedCategory[] = response.data;
                setOrganizationCategoriesPreferences(preferences);
            }
        }
    })

    const handleDeleteClick = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this  category!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, I am sure!',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteOrganization({});
            }
        })
    }

    const handleCancelClick = () => navigate(-1);

    return (
        <SuperAdminLayout>
            <div className="flex justify-between items-center mb-4">
                <div className='flex group'>
                    <BackButton />
                    <h2 className="text-3xl font-bold text-gray-800">
                        {isCreate ? "Create" : "Edit"} Organization
                    </h2>
                </div>

                {!isCreate &&
                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button className="p-2 rounded-full hover:bg-gray-200 transition-colors border-4 border-white hover:border-4 hover:border-gray-100">
                                <BsThreeDotsVertical
                                    className="text-gray-800 text-xl"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? 'bg-red-500 text-white' : 'text-gray-800'
                                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                onClick={handleDeleteClick}
                                                disabled={isDeleteLoading}
                                            >
                                                {
                                                    isDeleteLoading ? <VscLoading className="animate-soin" fontSize={32} />
                                                        :
                                                        <>
                                                            <FaTrash className="mr-2 font-semibold" />
                                                            Delete
                                                        </>
                                                }
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                }
            </div>

            <div className="p-4 mb-8 bg-gray-100 rounded">
                <h3 className="text-xl text-gray-800 font-semibold mb-2">Organization details</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-x-8">
                        <FormInput
                            label="Name"
                            name="name"
                            register={register}
                            className="mb-4"
                            errors={errors}
                        />

                        <label>
                            <FormLabel
                                label='Is Internal Organization?'
                                name='is_test_organization'
                            />
                            <div className='pt-4 w-fit'>
                                <input type='checkbox'
                                    id="is_test_organization"
                                    {...register('is_test_organization')}
                                    className="cursor-pointer w-4 h-4 text-primary-200 bg-gray-100 border-gray-300 rounded focus:ring-primary-300 dark:focus:ring-primary-200 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-4"
                                />
                            </div>
                        </label>

                        <FormImage
                            key={organization?.icon}
                            image={organization?.icon}
                            setImage={setOrganizationLogo}
                        />
                    </div>

                    <div className="flex gap-4 mt-4">
                        <Button
                            type="button"
                            onClick={handleCancelClick}
                            label="Cancel"
                            varient="outline-danger"
                            disabled={isLoading}
                            isLoading={isLoading}
                        />
                        <Button
                            label="Submit"
                            disabled={isLoading}
                            isLoading={isLoading}
                        />
                    </div>
                </form>
            </div>

            {
                organization &&
                <OrganizationCategoriesListing
                    organizationId={organization?.id}
                    categoriesPreferences={organizationCategoriesPreferences}
                    is_test_organization={organization.is_test_organization ?? false}
                />
            }
        </SuperAdminLayout>
    )
}

