import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'

//
import Button from '../../components/Button'
import FormInput from '../../components/FormInput'
import FormLabel from '../../components/FormLabel'

//
import { ICategory, ICategoryCreateProps } from '../../types'
import { useMutationHook } from '../../hooks/react-query/useQueryHook'
import { categorySchema } from '../../schemaValidations'

/**
 * 
 */
export default function CategoryForm({
    category,
    hideForm,
}: ICategoryCreateProps) {
    const queryClient = useQueryClient()

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            title: '',
            for_test: false,
        },
        resolver: yupResolver(categorySchema),
    })

    useEffect(() => {
        if (!category) return

        setValue('title', category.title)
        setValue('for_test', category.for_test)
    }, [category, setValue])

    const { mutate, isLoading: isCreateCategoryLoading } = useMutationHook({
        queryRoute: '/categories/',
        options: {
            onSuccess: () => {
                queryClient.invalidateQueries(['category-listing'])
                hideForm()
                reset()
            },
            onError: (err) => {
                toast.error(err.detail)
            },
        },
    })

    const { mutate: updateCategory, isLoading: isUpdateCategoryLoading } =
        useMutationHook({
            queryRoute: `/categories/${category?.id}/`,
            method: 'patch',
            options: {
                onSuccess: () => {
                    queryClient.invalidateQueries(['category-listing'])
                    queryClient.invalidateQueries([`category-${category?.id}`])

                    hideForm()
                    reset()
                },
                onError: (err) => {
                    toast.error(err.detail)
                },
            },
        })

    const isLoading = isCreateCategoryLoading || isUpdateCategoryLoading

    const onSubmit = (data: ICategory) => {
        if (category) {
            updateCategory(data)
        } else {
            mutate(data)
        }
    }

    return (
        <div className="py-5 px-5 bg-gray-100 rounded mb-8">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <FormInput
                        name="title"
                        label="Title"
                        register={register}
                        errors={errors}
                        disabled={isLoading}
                    />

                    <label>
                        <FormLabel
                            label='Is Internal exam Subject?'
                            name='for_test'
                        />
                        <div className='pt-4 w-fit'>
                            <input type='checkbox'
                                id="for_test"
                                {...register('for_test')}
                                className="cursor-pointer w-4 h-4 text-primary-200 bg-gray-100 border-gray-300 rounded focus:ring-primary-300 dark:focus:ring-primary-200 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-4"
                            />
                        </div>
                    </label>
                </div>

                <div className="flex justify-end gap-4">
                    <Button
                        className="mt-5"
                        label="Cancel"
                        varient="outline-danger"
                        disabled={isLoading}
                        isLoading={isLoading}
                        type="button"
                        onClick={hideForm}
                    />
                    <Button
                        className="mt-5"
                        label="Submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                </div>
            </form>
        </div>
    )
}
