import { Fragment } from 'react'
import { Link } from 'react-router-dom'

//
import {
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineEye,
    AiOutlinePlus,
} from 'react-icons/ai'
import { VscLoading } from 'react-icons/vsc'

//
import {
    IActionColumnProps,
    IActionContainerProps,
    ICustomTableProps,
} from '../types'

//
import Button from './Button'

/**
 * 
 */
export default function CustomTable({
    headers = [],
    data = [],
    callerId,
    isLoading,
    tableClassName,
}: ICustomTableProps) {
    return (
        <table
            className={[
                'w-full text-sm text-left rtl:text-right text-gray-400',
                tableClassName,
            ].join(' ')}
        >
            <thead className="text-xs uppercase bg-primary-200">
                {headers?.map((head, i) => (
                    <tr key={i}>
                        {head?.map((h) => (
                            <th
                                key={h.key}
                                rowSpan={h?.rowSpan}
                                colSpan={h?.colSpan}
                                className={[
                                    'px-6 py-4 font-medium text-white whitespace-nowrap ',
                                    h?.className ? h?.className : '',
                                ].join(' ')}
                            >
                                {h?.title}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            {!isLoading && data && data?.length === 0 && (
                <tbody>
                    <tr className="border-b bg-gray-100 border-gray-800">
                        <td
                            colSpan={headers?.flat()?.length}
                            className="text-center text-gray-800 px-6 py-4"
                        >
                            Not found
                        </td>
                    </tr>
                </tbody>
            )}
            {isLoading ? (
                <tbody>
                    <tr className="border-b bg-gray-100 border-gray-800">
                        <td
                            colSpan={headers?.flat()?.length}
                            className="px-6 py-4"
                        >
                            <VscLoading
                                fontSize={40}
                                className="mx-auto animate-spin"
                            />
                        </td>
                    </tr>
                </tbody>
            ) : (
                <tbody>
                    {data?.map((d: any, i: number) => (
                        <tr
                            className={`transition-colors hover:bg-gray-300 bg-gray-100 text-gray-900 ${callerId && d?.user?.id === callerId
                                ? 'bg-red-500 text-white animate-bounce'
                                : ''
                                }`}
                            key={data?.id ?? i}
                        >
                            {headers?.flat().map((head) => (
                                <td
                                    key={head.key}
                                    className={[
                                        'px-6 py-4',
                                        head?.dataClassName
                                            ? head.dataClassName
                                            : '',
                                    ].join(' ')}
                                >
                                    {head?.render ? (
                                        head?.render(d, i)
                                    ) : d[head.key] ? (
                                        d[head.key]
                                    ) : (
                                        <small className="text-secondary">
                                            N/A
                                        </small>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            )}
        </table>
    )
}

const ActionContainer = ({ path, children }: IActionContainerProps) => {
    if (!!path) {
        return <Link to={path}>{children}</Link>
    }
    return <Fragment>{children}</Fragment>
}

const Add = ({ path, onClick }: IActionColumnProps) => {
    return (
        <ActionContainer path={path}>
            <Button
                title="Add"
                icon={<AiOutlinePlus />}
                {...(onClick ? { onClick } : {})}
            />
        </ActionContainer>
    )
}

const Edit = ({ path, onClick }: IActionColumnProps) => {
    return (
        <ActionContainer path={path}>
            <Button
                title="Edit"
                varient='outline-primary'
                icon={<AiOutlineEdit />}
                {...(onClick ? { onClick } : {})}
            />
        </ActionContainer>
    )
}
const View = ({ path, onClick }: IActionColumnProps) => {
    return (
        <ActionContainer path={path}>
            <Button
                title="View"
                varient='outline-primary'
                icon={<AiOutlineEye />}
                {...(onClick ? { onClick } : {})}
            />
        </ActionContainer>
    )
}

const Delete = ({ path, onClick }: IActionColumnProps) => {
    return (
        <ActionContainer path={path}>
            <Button
                title="Delete"
                varient='danger'
                icon={<AiOutlineDelete />}
                {...(onClick ? { onClick } : {})}
            />
        </ActionContainer>
    )
}

export const Action = Object.assign({
    Edit,
    View,
    Add,
    Delete,
})
