import packageJson from '../../package.json';

const Versioning = () => {
    return (
        <div
            className='text-gray-400 text-right'
        >
            v{packageJson.version}
        </div>
    );
};

export default Versioning;
