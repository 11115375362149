import Swal from 'sweetalert2'
import ExamSidebarButton from './ExamSidebarButtons'
import Timer from './Timer'

import { FaHand } from 'react-icons/fa6'
import { useAppDispatch, useAppSelector } from '../_redux/redux'
import {
    handleNextQuestion,
    handlePreviousQuestion,
    handleSetAnswers,
    handleSetQuestionIndex,
    handleSetResult,
} from '../_redux/exam'
import { useMutationHook, useQueryHook } from '../hooks/react-query/useQueryHook'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import LoadingModal from './LoadingModal'
import { useState } from 'react'

export default function ExamSidebar() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [callInvigilator, setCallInvigilator] = useState(false);

    const selectedOptions = useAppSelector(
        (state) => state.exam.selectedOptions
    )
    const answers = useAppSelector((state) => state.exam.answers)
    const currentQuestion = useAppSelector(
        (state) => state.exam.currentQuestion
    )

    useQueryHook({
        queryName: "call-invigilator",
        queryRoute: `/call/`,
        options: {
            enabled: callInvigilator,
            onSuccess: () => {
                toast.success('Calling Invigilator!');
                setCallInvigilator(false);
            }
        }
    })

    const questions = useAppSelector((state) => state.exam.questions)

    const handleCallInvigilator = () => {
        setCallInvigilator(true);
    }

    const handlePreviousBtnClick = () => {
        dispatch(
            handleSetAnswers({
                answers: selectedOptions,
                questionIndex: currentQuestion,
            })
        )
        dispatch(handlePreviousQuestion())
    }

    const handleNextBtnClick = async () => {
        dispatch(
            handleSetAnswers({
                answers: selectedOptions,
                questionIndex: currentQuestion,
            })
        )

        dispatch(handleNextQuestion())
    }

    const handleFirstPageClick = async () => {
        dispatch(
            handleSetAnswers({
                answers: selectedOptions,
                questionIndex: currentQuestion,
            })
        )

        dispatch(handleSetQuestionIndex(0))
    }

    const handleLastPageClick = async () => {
        dispatch(
            handleSetAnswers({
                answers: selectedOptions,
                questionIndex: currentQuestion,
            })
        )

        dispatch(handleSetQuestionIndex(questions.length - 1))
    }

    const { mutate: submitAnswers, isLoading } = useMutationHook({
        queryRoute: '/create-answers/',
        options: {
            onSuccess: (response: any) => {
                dispatch(handleSetResult(response.data))
                navigate('/applicant/result')
            },
            onError: (errors) => {
                errors.map((error: string) => toast.error(error))
            },
        },
    });

    const handleQuitAndSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to quit and submit the Assessment!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, submit it!',
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmitAnswers();
            }
        })
    }

    const handleSubmitAnswers = () => {
        let answersArr = Object.values(answers).flat()
        submitAnswers({ options: answersArr })
    }

    return (
        <div className="lg:w-[300px] min-h-screen bg-light-100 flex flex-col">
            <div className="border-2 min-h-[80px] w-full flex justify-center items-center">
                <Timer handleSubmitAnswers={handleSubmitAnswers} />
            </div>

            <ExamSidebarButton
                onClick={handleCallInvigilator}
                label="Call"
                icon={<FaHand fontSize={20} />}
                className="bg-green-500 text-light-100 hover:bg-green-600"
            />

            <ExamSidebarButton
                onClick={handleFirstPageClick}
                label="First page"
                disabled={currentQuestion === 0}
                className="bg-light-500 text-gray-700 hover:bg-gray-100"
            />

            <ExamSidebarButton
                onClick={handleLastPageClick}
                label="Last page"
                disabled={currentQuestion === questions.length - 1}
                className="bg-light-500 text-gray-700 hover:bg-gray-100"
            />

            <ExamSidebarButton
                onClick={handleNextBtnClick}
                label="Next"
                className="bg-light-500 text-gray-700 hover:bg-gray-100"
                disabled={currentQuestion === questions.length - 1}
            />

            <ExamSidebarButton
                onClick={handlePreviousBtnClick}
                label="Previous"
                className="bg-light-500 text-gray-700 hover:bg-gray-100"
                disabled={!Boolean(currentQuestion)}
            />

            <ExamSidebarButton
                onClick={handleQuitAndSubmit}
                label="Quit and submit"
                className="bg-red-500 text-light-100 hover:bg-red-600"
            />

            <LoadingModal show={isLoading} />
        </div>
    )
}
