import React from 'react'
import { ISearchInputProps } from '../types'
import { FaSearch } from 'react-icons/fa';

function SearchInput({ value, onChange, placeholder }: ISearchInputProps) {

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => onChange(e.currentTarget.value);

    return (
        <div className='relative'>
            <FaSearch
                className='absolute top-[9px] left-2 text-gray-400'
            />
            <input
                className='pl-8 pr-2 py-1 border-2 border-gray-400 outline-primary-200'
                placeholder={placeholder}
                value={value}
                onChange={handleSearchChange}
            />
        </div>
    )
}

export default SearchInput