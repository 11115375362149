import { useEffect, useState } from "react";
import FormLabel from "./FormLabel";
import { IFormImageProps } from "../types";

export default function FormImage({
    image,
    setImage
}: IFormImageProps) {
    const [preview, setPreview] = useState<string>()
    const [selectedFile, setSelectedFile] = useState()

    useEffect(() => {
        if (!image) return;
        setPreview(image);
    }, [image])

    useEffect(() => {
        if (!selectedFile) return;

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            setPreview(undefined)
            return
        }

        const file = e.target.files[0];
        setImage(file);
        setSelectedFile(file);
    }
    return (
        <div>
            <FormLabel name="image" label="Logo" />

            <input className="block w-full text-sm text-gray-800 border border-gray-300 rounded cursor-pointer bg-gray-50 focus:outline-none px-2 py-1 mt-1" id="multiple_files" type="file" onChange={onSelectFile} accept="image/*" />

            {preview &&
                <div className="p-2">
                    <div className="mx-auto max-w-[200px] border-4 border-primary-200 rounded overflow-hidden">
                        <img src={preview} alt="" className="max-w-full object-cover h-auto" />
                    </div>
                </div>
            }
        </div>
    )
}