import { useAppSelector } from '../_redux/redux'
import AdminHeader from '../components/AdminHeader'
import SuperAdminHeader from '../components/SuperAdminHeader';

export default function AdminLayout({ children }: any) {
    const user = useAppSelector((state) => state.user.user);

    const header = user.role === "superuser" ? <SuperAdminHeader /> : <AdminHeader />
    return (
        <div className="min-h-screen w-full flex flex-col bg-light-200">
            {header}

            <div className="bg-light-100 m-8 p-8">{children}</div>
        </div>
    )
}
