import { useNavigate } from 'react-router-dom'

//
import Image from '../../elements/Image'
import Button from '../../components/Button'
import LabelValueText from '../../elements/LabelValueText'

//
import { useAppSelector } from '../../_redux/redux'

//
import Logo from '../../assets/logo.png'
import { IStudentRegister } from '../../types'

//
import { getLicenceFromUsername } from '../../helpers/methods'
import { useMutationHook } from '../../hooks/react-query/useQueryHook'
import { toast } from 'react-toastify'

//
import CCTV from '../../assets/cctv.svg';

/**
 * 
 */
export default function ExamStart() {
    const navigate = useNavigate();
    const remainingTime = useAppSelector(state => state.exam.remainingTime);
    const user: IStudentRegister = useAppSelector((state) => state.user.user);

    const { mutate: startTimer, isLoading } = useMutationHook({
        queryRoute: '/start/',
        options: {
            onSuccess: (response: any) => {
                navigate('/applicant/exam')
            },
            onError: (err) => {
                toast.error(err.detail)
            },
        },
    })

    const handleStart = () => {
        startTimer({});
    }

    return (
        <div className="min-h-screen flex items-start bg-light-200">
            <div className=" flex justify-center items-start mt-10 p-10 bg-light-100 mx-auto w-[90%] sm:w-[540px] md:w-[540px] lg:w-[760px] xl:w-[1200px]">
                <div className="flex items-center flex-col">
                    <Image
                        src={Logo}
                        alt="geeta aviation"
                        className="!max-w-[325px]"
                    />

                    <div className="mt-12 text-gray-800">
                        <h1 className="font-bold text-4xl text-center uppercase">
                            Airline Pilot Assessment
                        </h1>
                        <h2 className="text-center font-bold text-2xl uppercase my-5">
                            {user.organization_name}
                        </h2>
                    </div>

                    <hr className="w-4/5 border-2" />

                    {user && (
                        <div className="w-full mt-6 lg:w-[600px]">
                            <div>
                                <LabelValueText
                                    label="Name"
                                    value={`${user.first_name} ${user.last_name}`}
                                />
                                <LabelValueText
                                    label="License number"
                                    value={getLicenceFromUsername(user.username ?? "")}
                                />
                                <LabelValueText
                                    label="Duration"
                                    value="1Hr 20mins"
                                />


                                <div className='my-8 flex justify-between border-4 border-orange-500 bg-orange-50 p-8 rounded border-dashed'>
                                    <div>
                                        <img src={CCTV} alt="camera" />
                                    </div>

                                    <div className='text-gray-700 max-w-96'>
                                        <h3 className='text-3xl font-bold mb-2'>You are under CCTV Surveillance.</h3>
                                        <h4 className='text-xl'>
                                            Any instances of cheating discovered will result in immediate disqualification.
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-10 flex items-center justify-end">
                                <Button label={remainingTime === 4800 ? 'Start' : 'Continue'} className='w-full justify-center' onClick={handleStart} disabled={isLoading} isLoading={isLoading} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
