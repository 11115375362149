import axios, { InternalAxiosRequestConfig } from 'axios'

//
import { jwtCheck } from '../../helpers/token-handler'
/**
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Axios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
})


// defining a custom error handler for all APIs
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => {
    if (error.response) {
        throw error.response.data
    } else if (error.request) {
        if (!error.statusCode) {
            throw new Error('Network error')
        }
        throw error.request
    } else {
        console.log(error, 'Erroor ELSE')
        throw new Error('Network error')
    }
}

// registering the custom error handler to the
// "Axios" axios instance
Axios.interceptors.response.use(
    (response) => response,
    (error) => {
        return errorHandler(error)
    }
)

interface InternalAxiosRequestConfigExtended
    extends InternalAxiosRequestConfig {
    refreshToken?: string
    multipart?: boolean
}

Axios.interceptors.request.use(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (config: InternalAxiosRequestConfigExtended) => {
        const Token = config.refreshToken || jwtCheck()
        if (Token) {
            config.headers['Authorization'] = `Bearer ${Token}`
        }
        if (config.multipart) {
            config.headers['Content-Type'] = 'multipart/form-data'
        } else {
            config.headers['Content-Type'] = 'application/json'
        }

        return config
    },
    (error) => {
        return errorHandler(error)
    }
)

export { Axios }

// reference: https://semaphoreci.com/blog/api-layer-react
