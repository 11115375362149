import { VscLoading } from "react-icons/vsc";
import { useMutationHook, useQueryHook } from "../hooks/react-query/useQueryHook";
import { ICategoriesCountInputProps, ICategory, ICategoryTableItemProps } from "../types";
import Button from "./Button";
import { FaSave } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

export default function CategoriesCountInput({ organizationId, categoriesPreferences, forTest }: ICategoriesCountInputProps) {
    const { data: examCategories, isLoading: isExamCategoryLoading } = useQueryHook({
        queryName: 'categories',
        queryRoute: '/categories/?limit=50',
        options: {
            enabled: !forTest
        }
    });

    const { data: testCategories, isLoading: isTestCategoryLoading } = useQueryHook({
        queryName: 'categories',
        queryRoute: '/categories/?limit=50&for_test=true',
        options: {
            enabled: forTest
        }
    });

    const isLoading = isExamCategoryLoading || isTestCategoryLoading;
    const categories = forTest ? testCategories : examCategories;

    const [activeCategory, setActiveCategory] = useState<number | undefined>();

    const selectedCategoriesObject: any = categoriesPreferences?.reduce((acc, cur) => {
        return ({
            categories: {
                ...acc.categories,
                [cur.category]: cur.no_of_question
            },
            count: acc.count + +cur.no_of_question
        })
    }, {
        categories: {},
        count: 0
    });

    const handleSetActiveCategory = (id: number) => setActiveCategory(id);

    return (
        <div>
            <table
                className="w-full text-sm text-left rtl:text-right text-gray-400">
                <thead className={`text-xs uppercase ${forTest ? 'bg-red-400' : 'bg-primary-200'}`}>
                    <tr>
                        <th
                            className="px-6 py-4 font-medium text-white whitespace-nowrap"
                        >
                            Subject
                        </th>
                        <th
                            className="px-6 py-4 font-medium text-white whitespace-nowrap"
                        >
                            Questions count
                        </th>
                        <th
                            className="px-6 py-4 font-medium text-white whitespace-nowrap"
                        >
                            Action
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {
                        isLoading ?
                            <tr>
                                <td colSpan={2}>
                                    <VscLoading className="animate-spin" fontSize={26} />
                                </td>
                            </tr>
                            :
                            <>
                                {
                                    categories?.data?.results?.length > 0 ?
                                        categories.data.results.map((category: ICategory) => {
                                            let count = (category?.id && selectedCategoriesObject) ? selectedCategoriesObject.categories[category.id] ?? 0 : 0;
                                            return (
                                                <CategoryTableItem
                                                    organizationId={organizationId}
                                                    category={category}
                                                    count={count}
                                                    active={activeCategory}
                                                    handleSetActiveCategory={handleSetActiveCategory}
                                                    forTest={forTest}
                                                />
                                            )
                                        })
                                        :
                                        <td colSpan={3}>
                                            No categories
                                        </td>

                                }
                            </>
                    }
                </tbody>

                <tfoot className="sticky bottom-0 rounded-b-2xl overflow-hidden w-full">
                    <tr>
                        <td className="py-6 bg-slate-600 rounded-bl-md w-full overflow-hidden block">
                        </td>
                        <td className="text-xl bg-slate-600 rounded-br-md text-white px-6 py-2.5">
                            <span className="text-sm"> Total:</span> <span className="font-bold">{selectedCategoriesObject.count}</span>
                        </td>
                        <td className="py-6 bg-slate-600 rounded-bl-md w-full overflow-hidden block">
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div >
    )
}

const CategoryTableItem = ({ active, organizationId, category, count, handleSetActiveCategory, forTest }: ICategoryTableItemProps) => {
    const queryClient = useQueryClient();
    const [questionCount, setQuestionCount] = useState<number>();

    useEffect(() => {
        setQuestionCount(count);
    }, [count])

    const handleSuccess = () => {
        queryClient.invalidateQueries(['organization-preferred-categories']);
        toast.success('Question count updated.')
    }

    const { mutate, isLoading } = useMutationHook({
        queryRoute: `/organizations/${organizationId}/preferred-categories/`,
        options: {
            onSuccess: handleSuccess
        }
    })

    const handleSaveClick = () => {
        mutate({
            organization: organizationId,
            no_of_question: questionCount,
            category: category.id
        })
    }

    const handleCountChange = (e: any, max: number) => {
        let value = e.target.value;
        if (value > max) return e.preventDefault();
        setQuestionCount(value)
    }
    const onRowClick = () => category.id && handleSetActiveCategory(category.id);

    return (
        <tr className={`border-b-2 ${active === category.id ? `${forTest ? "bg-red-50 hover:bg-red-100": "bg-blue-300 hover:bg-blue-100"}` : "hover:bg-gray-300"} `} onClick={onRowClick}>
            <td className="text-xl text-gray-800 px-6 py-4">
                {category.title} <span className='text-xs text-gray-600 uppercase'>total questions: {category.count}</span>
            </td>

            <td>
                <input
                    type="number"
                    className="p-1 text-xl text-gray-800"
                    value={questionCount}
                    disabled={isLoading || active !== category.id}
                    onChange={(e) => handleCountChange(e, category.count ?? 0)}
                    min={0}
                    max={category.count}
                />
            </td>

            <td>
                <Button
                    varient={forTest ? "secondary" : "primary"}
                    icon={<FaSave />}
                    label="Save"
                    className="ml-4"
                    isLoading={isLoading}
                    disabled={isLoading || active !== category.id}
                    onClick={handleSaveClick}
                />
            </td>
        </tr>
    )
}