import { yupResolver } from '@hookform/resolvers/yup'

//
import Image from '../../elements/Image'

//
import Logo from '../../assets/logo.png'
import { useForm } from 'react-hook-form'
import loginSchema from '../../schemaValidations'
import { useMutationHook } from '../../hooks/react-query/useQueryHook'
import { useAppDispatch } from '../../_redux/redux'
import { useNavigate } from 'react-router-dom'
import { handleSetCredentials } from '../../_redux/authSlice'
import { toast } from 'react-toastify'
import { ILoginFormProps } from '../../types'
import FormInput from '../../components/FormInput'
import Button from '../../components/Button'
import { useQueryClient } from 'react-query'
import Versioning from '../../components/Versoning'

/**
 *
 */
export default function LoginPage() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            username: '',
            password: '',
        },
        resolver: yupResolver(loginSchema),
    })

    const { mutate, isLoading } = useMutationHook({
        queryRoute: '/login/',
        options: {
            onSuccess: (data) => {
                dispatch(handleSetCredentials(data.data))
                queryClient.invalidateQueries(['me']);
                navigate('/student-register')
            },
            onError: (err) => {
                toast.error(err.detail)
            },
        },
    })

    const onSubmit = (data: ILoginFormProps) => {
        mutate(data)
    }

    return (
        <div className="min-h-screen flex justify-center items-start bg-light-200">
            <div>
                <div className="p-10 mt-28 bg-light-100 max-w-[430px]">
                    <Image
                        src={Logo}
                        alt="Geeta Aviation"
                        className="!max-w-[350px] px-5 mb-12 mx-auto"
                    />

                    <h3 className="font-bold mb-3 text-gray-700 text-2xl">Login</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormInput
                            name="username"
                            label="Username"
                            register={register}
                            className="mb-4"
                            errors={errors}
                        />
                        <FormInput
                            name="password"
                            label="Password"
                            type="password"
                            register={register}
                            className="mb-8"
                            errors={errors}
                        />

                        <Button
                            label="Login"
                            isLoading={isLoading}
                            disabled={isLoading}
                        />
                    </form>
                </div>
                <Versioning />
            </div>
        </div>
    )
}
