import { useEffect, useState } from 'react'
import { handleSetQuestions } from '../../_redux/exam'
import { useAppDispatch, useAppSelector } from '../../_redux/redux'
import Question from '../../components/Question'
import { useQueryHook } from '../../hooks/react-query/useQueryHook'
import ExamLayout from '../../layout/ExamLayout'
import { IQuestion } from '../../types'
import Loader from '../../components/Loader'
import ExamProgress from '../../components/ExamProgress'

export default function ExamPage() {
    const dispatch = useAppDispatch()
    const questions = useAppSelector((state) => state.exam.questions)
    const currentQuestionIndex = useAppSelector(
        (state) => state.exam.currentQuestion
    )

    const [question, setQuestion] = useState<IQuestion>()

    const handleQuestionsFetchSuccess = (response: any) => {
        dispatch(handleSetQuestions(response.data))
    }

    const { isLoading } = useQueryHook({
        queryName: 'questions',
        queryRoute: '/list-questions/',
        options: {
            onSuccess: handleQuestionsFetchSuccess,
        },
    })

    useEffect(() => {
        if (questions) {
            let currentQuestion: IQuestion = questions[currentQuestionIndex]
            setQuestion(currentQuestion)
        }
    }, [questions, currentQuestionIndex])

    return (
        <ExamLayout>
            <>
                <div  className="bg-light-100 m-6 px-6 py-4 ">
                    <ExamProgress
                        total={(questions ?? []).length}
                        current={currentQuestionIndex}
                    />
                </div>

                <div className="bg-light-100 mx-6 p-6">
                    {
                        isLoading ? <div className='min-h-[400px] flex justify-center items-center'><Loader /></div> : question && (
                            <Question
                                question={question}
                                totalQuestions={questions.length}
                            />
                        )
                    }
                </div>
            </>
        </ExamLayout>
    )
}
