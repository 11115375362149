import { useState } from 'react'
import CustomTable, { Action } from '../../components/CustomTable'
import SuperAdminLayout from '../../layout/SuperAdminLayout'
import {
    useMutationHook,
    useQueryHook,
} from '../../hooks/react-query/useQueryHook'
import Button from '../../components/Button'
import { IoMdAdd } from 'react-icons/io'
import Pagination from '../../components/Pagination'
import { IScheduleProps } from '../../types'
import Swal from 'sweetalert2'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import ExamScheduleForm from './ExamScheduleForm'
import dayjs from 'dayjs'
import BackButton from '../../components/BackButton'

export default function ExamScheduleListingPage() {
    const [examSchedule, setExamSchedule] = useState([])
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(false)
    const [schedule, setSchedule] = useState<IScheduleProps>()

    const handleOpenUserForm = () => {
        if (open) {
            setSchedule(undefined)
        }
        setOpen((prev) => !prev)
    }

    const handleSetPage = (page: number) => {
        setPage(page)
    }

    const { isLoading } = useQueryHook({
        queryName: 'schedules',
        queryRoute: `/schedules/`,
        params: [
            ['offset', (page - 1) * 10],
            ['limit', 10],
        ],
        options: {
            onSuccess: (response: any) => {
                setExamSchedule(response.data.results)
                setCount(response.data.count)
            },
        },
    })

    const ExamTableHeaders = [
        [
            {
                key: 'organization_name',
                title: 'Organization',
            },
            {
                key: 'start_date',
                title: 'Exam Date',
                render: (schedule: any) => (
                    <span>
                        {dayjs(schedule.start_time).format('YYYY-MM-DD')}
                    </span>
                ),
            },
            {
                key: 'start_time',
                title: 'Start Time',
                render: (schedule: any) => (
                    <span>{dayjs(schedule.start_time).format('hh:mm a')}</span>
                ),
            },
            {
                key: 'end_time',
                title: 'End Time',
                render: (schedule: any) => (
                    <span>{dayjs(schedule.end_time).format('hh:mm a')}</span>
                ),
            },

            {
                key: 'actions',
                title: 'Action',
                render: (schedule: any) => (
                    <>
                        <span className="flex gap-4">
                            <Action.Edit
                                onClick={() => {
                                    setOpen(true)
                                    setSchedule(schedule)
                                }}
                            />
                            <DeleteButton id={schedule.id} />
                        </span>
                    </>
                ),
            },
        ],
    ]
    return (
        <SuperAdminLayout>
            <div className="flex justify-between items-center mb-6 ">
                <div className='flex group'>
                    <BackButton />
                    <h2 className="text-3xl font-bold text-gray-800">Scheduled Exams</h2>
                </div>
                <Button
                    icon={<IoMdAdd />}
                    label="Add Exam Schedule"
                    onClick={() => handleOpenUserForm()}
                />
            </div>

            {open && (
                <div>
                    <ExamScheduleForm
                        key={schedule ? schedule.id : ''}
                        hideForm={handleOpenUserForm}
                        schedule={schedule}
                    />
                </div>
            )}

            <div className="w-full mt-4 overflow-auto">
                <CustomTable
                    headers={ExamTableHeaders}
                    data={examSchedule}
                    callerId={0}
                    isLoading={isLoading}
                />
            </div>
            <div>
                <Pagination
                    total={count}
                    perPage={10}
                    setPage={handleSetPage}
                    page={page}
                />
            </div>
        </SuperAdminLayout>
    )
}

const DeleteButton = ({ id }: { id: number }) => {
    const queryClient = useQueryClient()

    const { mutate: deleteUser } = useMutationHook({
        queryRoute: `/schedules/${id}/`,
        method: 'delete',
        options: {
            onSuccess: () => {
                queryClient.invalidateQueries(['schedules'])
            },
            onError: (err) => {
                toast.error(err.detail)
            },
        },
    })

    return (
        <Action.Delete
            onClick={() => {
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'You want to delete this exam schedule!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I am sure!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        deleteUser({})
                    }
                })
            }}
        />
    )
}
