import { IRules } from "../types";

const rules: IRules = {
  superuser: {
    static: [
      'create:category',
      'change:category'
    ],
  },
  question_admin: {
    static: [

    ],
  },
  staff: {
    static: []
  },
  applicant: {
    static: []
  }
};

export default rules;
