import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Disclosure } from '@headlessui/react'

//
import { IQuestionItemProps } from "../../types"

//
import {
    useMutationHook,
} from '../../hooks/react-query/useQueryHook'

//
import Button from '../../components/Button'

//
import { IoChevronUp } from 'react-icons/io5'
import { AiOutlineEdit } from 'react-icons/ai'
import { MdDeleteOutline } from 'react-icons/md'

/**
 * 
 */
export default function QuestionItem({ question, role, id }: IQuestionItemProps) {
    const navigate = useNavigate()
    return (
        <Disclosure key={question.id}>
            {({ open }) => (
                <>
                    <Disclosure.Button className={`${open ? "" : "mb-2"} flex w-full justify-between items-center bg-gray-100 px-4 py-2 text-left text-sm text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75`}>
                        <p>
                            <span>{question.question}</span> &nbsp;{' '}
                        </p>
                        <div className="flex items-center">
                            <Button
                                icon={<AiOutlineEdit />}
                                varient="outline-primary"
                                className="mr-4"
                                onClick={() => {
                                    navigate(
                                        `/${role}/questions/${id}/edit/${question.id}`
                                    )
                                }}
                            />
                            <DeleteQuestion
                                id={question.id}
                                category_id={id}
                            />

                            <IoChevronUp
                                className={`transition-transform ${open
                                    ? 'rotate-180 transform'
                                    : ''
                                    } h-5 w-5 text-gray-500`}
                            />
                        </div>
                    </Disclosure.Button>
                    <Disclosure.Panel>
                        <div className="bg-gray-50 mb-2">
                            {question.option_set.map((option) => (
                                <p
                                    className={`${option.is_correct
                                        ? 'bg-green-100'
                                        : ''
                                        } py-1 px-4 mb-1 text-sm text-gray-500`}
                                    key={option.id}
                                >
                                    {option.option}
                                </p>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )
            }
        </Disclosure >
    )
}


const DeleteQuestion = ({
    id,
    category_id,
}: {
    id: number
    category_id: string
}) => {
    const queryClient = useQueryClient()

    const { mutate: deleteQuestion, isLoading: deletingQuestion } =
        useMutationHook({
            method: 'delete',
            queryRoute: `/questions/${id}/`,
            options: {
                onSuccess: () => {
                    queryClient.invalidateQueries([`questions-${category_id}`])
                },
                onError: (err) => {
                    toast.error(err.detail)
                },
            },
        })
    return (
        <Button
            icon={<MdDeleteOutline />}
            varient="danger"
            className="mr-4"
            onClick={() => {
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'You want to delete this question!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, I am sure!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        deleteQuestion({ id })
                    }
                })
            }}
            isLoading={deletingQuestion}
            disabled={deletingQuestion}
        />
    )
}
