import { Outlet } from 'react-router-dom'
import { useQueryHook } from '../../hooks/react-query/useQueryHook'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../_redux/redux'
import { handleSetUser } from '../../_redux/userSlice'
import Speech from '../../components/Speech'
import { logOutUser } from '../../_redux/authSlice'

export default function AuthLayout() {
    const dispatch = useAppDispatch();
    const caller: any = useAppSelector(state => state.socket.caller);
    const [ring, setRing] = useState<boolean>(false);

    const handleMeError = (error: any) => {
        dispatch(logOutUser());
    }

    const { data: userData } = useQueryHook({
        queryName: 'me',
        queryRoute: `/user/me/`,
        options: {
            staleTime: 120000,
            onError: handleMeError
        },
    })

    useEffect(() => {
        if (userData) {
            dispatch(handleSetUser(userData.data))
        }
    }, [dispatch, userData])


    useEffect(() => {
        if (caller.username) {
            setRing(true);
        }
        else {
            setRing(false);
        }
    }, [caller]);

    return (
        <div className="auth max-h-screen">
            <div className="auth-main">
                <Outlet />
            </div>
            <Speech
                key={caller.seat_plan}
                text={`.Seat number. ${caller.seat_plan}. is Calling.`}
                play={ring}
            />
        </div>
    )
}
