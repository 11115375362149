import { IFormInput } from '../types'
import FormLabel from './FormLabel'
import Password from './Password'

/**
 *
 */
export default function FormInput({
    label,
    name,
    type,
    placeholder = '',
    register,
    className,
    errors,
    isRequired,
    disabled,
    rowsSpan = 2,
}: IFormInput) {
    const hasError = Boolean(errors && errors[name])
    let element = null

    switch (type) {
        case 'password':
            element = (
                <Password
                    name={name}
                    isRequired={isRequired}
                    register={register}
                    disabled={disabled}
                />
            )
            break
        case 'textarea':
            element = (
                <div className="mt-1">
                    <textarea
                        name={name}
                        id={name}
                        type={type}
                        {...(register ? register(name) : {})}
                        // required={isRequired}
                        className="ga_input min-w-[238px]"
                        placeholder={placeholder}
                        disabled={disabled}
                        required={isRequired}
                        rows={rowsSpan}
                    />
                </div>
            )
            break

        default:
            element = (
                <div className="mt-1">
                    <input
                        name={name}
                        id={name}
                        type={type}
                        {...(register ? register(name) : {})}
                        className="ga_input min-w-[238px] bg-white"
                        placeholder={placeholder}
                        disabled={disabled}
                        required={isRequired}
                    />
                </div>
            )
            break
    }
    return (
        <div className={`${className ?? ''}`}>
            {label && (
                <FormLabel
                    name={name}
                    label={label}
                />
            )}

            {element}

            {hasError && (
                <div className="text-red-600 mt-1 text-sm">
                    {errors[name].message}
                </div>
            )}
        </div>
    )
}
