import { logOutUser } from '../_redux/authSlice'
import { useAppDispatch, useAppSelector } from '../_redux/redux'

//
import Button from './Button'
import Image from '../elements/Image'

//
import Logo from '../assets/logo.png'
import { FaSignOutAlt } from 'react-icons/fa'
import { handleResetUser } from '../_redux/userSlice'
import { Link, NavLink } from 'react-router-dom'
import { defaultRouteForUser } from '../constants'
import { ISuperadminNavLink } from '../types'
import { handleClearExamStudentInfo } from '../_redux/socket'

/**
 *
 */
export default function SuperAdminHeader() {
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.user.user)

    const handleLogout = () => {
        dispatch(logOutUser())
        dispatch(handleClearExamStudentInfo());
        dispatch(handleResetUser())
    }
    return (
        <div className="bg-white">
            <div className="container mx-auto h-[70px] px-4 py-3 flex items-center justify-between">
                <Link
                    to={user.role ? defaultRouteForUser[user.role] : '#'}
                    className="cursor-pointer h-full"
                >
                    <Image src={Logo} alt="geeta-aviation" className="h-full" />
                </Link>

                <div className='flex gap-8 items-center'>
                    {
                        SUPERADMIN_NAV_LINKS?.map((link) => (
                            <NavLink to={link.link} className={({ isActive }) => `capitalize font-medium ${isActive ? "text-blue-500" : 'text-gray-800'}`} key={link.link}>{link.label}</NavLink>
                        ))
                    }
                </div>

                <div className='flex items-center gap-8'>
                    <div className=" text-gray-800">
                        <h4 className="text-xl m-0 font-bold">
                            {user.first_name} {user.last_name}
                        </h4>
                        <p className="m-0 text-sm font-medium capitalize">
                            {user.role?.replace('_', ' ')}
                        </p>
                    </div>

                    <Button
                        className="flex justify-center"
                        onClick={handleLogout}
                        icon={<FaSignOutAlt />}
                    />
                </div>
            </div>
        </div>
    )
}

const SUPERADMIN_NAV_LINKS: ISuperadminNavLink[] = [
    {
        link: "/super-admin/users",
        label: "users"
    }, {
        link: "/super-admin/exam-schedule",
        label: "exam"
    },
    {
        link: "/super-admin/organizations",
        label: "organizations"
    },
    {
        link: "/super-admin/category",
        label: "Subjects"
    },
    {
        link: "/super-admin/questions",
        label: "Questions"
    },
]
